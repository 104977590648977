//Added by Celsia on 29-Jun-2021 for New Development
import React from 'react'
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import Badges from '../../components/badge/badge';
import { withStyles } from '@material-ui/core/styles';
import color from "../../utils/colors/colors";
import { Grid } from '@material-ui/core';
import Label from '../../utils/constants/labels';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoData from '../nodata/nodata';
import labels from '../../utils/constants/labels';

import { Encryption } from '../../utils/commonfunction';
const styles = theme => ({

  left: {
    textAlign: "left"
  },
  detailsContainer: {
    width: "100%",
    color: window.globalConfig.color.SECONDARY_BLUE,
    fontSize: "14px",
    // minWidth:'300px',
    fontWeight: 500,
    borderCollapse: "collapse",
    marginTop: "2em",
    // marginBottom: "15%",
    borderRadius: "10px 10px 10px 10px",
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
    padding: "20px",
    margin: 10,
    textAlign: "left",
       [theme.breakpoints.down('xs')]: {
      width: "100%",
      minWidth:"240px",
      justifyContent:'center',
      
      // minWidth:"320px"
      // margin:' 10px auto',
      // justifyContent:'center',
      // margin: 0,
      // marginTop: "1em",
      // padding: "15px",
    }
  },
  flex: {
    flexDirection: 'row',
    display: "flex",
    justifyContent: "space-between",
  },
  padding: {
    padding: 10
  },
  Container: {

    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    [theme.breakpoints.down('xs')]: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.down('xs')]: {
      display: "grid",
      gridTemplateColumns: "repeat(1,1fr)",
      // width:"250px",
    },
    // paddingLeft:"10px"
    // marginLeft:"20px"
  },
  nodataResponse: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px',
  }
})
class MyFamilyDetailsCard extends React.Component {



  render() {
    const { classes } = this.props

    return (<>
      {this.props.familyDetails.length == 0 ?
        <div className={classes.nodataResponse}>
          <NoData />
        </div> : <div className={classes.Container}>
          {this.props.familyDetails.map((item) => {
            return (
              <>
                <Grid container className={classes.flex} >
                  <Grid className={classes.detailsContainer}>
                    {/* <Grid className={classes.padding}>
                      <Badges color={window.globalConfig.color.shadeGreen} text={labels.messages.NA} textcolor="white" page={"profile"} />
                    </Grid> */}
                    <Grid xs={12} className={classes.flex} >
                      <Grid xs={6} className={classes.padding} >
                        <div className={classes.labelResponsive}>
                          <span >{Label.myFamilyDetails.FullName}</span>
                        </div>
                      </Grid>
                      <Grid xs={6} className={classes.padding}>
                        
                          <span >{typeof (item.ClientName) != "object" ? item.ClientName : labels.messages.NA}</span>
                        
                      </Grid>
                    </Grid>

                    <Grid xs={12} className={classes.flex}>
                      <Grid xs={6} className={classes.padding}>
                        <div className={classes.labelResponsive}>
                          <span>{Label.myFamilyDetails.Gender}</span>
                        </div>
                      </Grid>
                      <Grid xs={6} className={classes.padding}>
                        <div>
                          <span>{item.Gender || labels.messages.NA}</span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid xs={12} className={classes.flex}>
                      <Grid xs={6} className={classes.padding}>
                        <div className={classes.labelResponsive}>
                          <span>{Label.myFamilyDetails.DateofBirth}</span>
                        </div>
                      </Grid>
                      <Grid xs={6} className={classes.padding}>
                        <div>
                          <span>{item.DOB||labels.messages.NA}</span>
                        </div>
                      </Grid>
                    </Grid>

                    
                    {/*<Grid xs={12} className={classes.flex}>
                      <Grid xs={6} className={classes.padding}>
                        <div className={classes.labelResponsive}>
                          <span>{Label.myFamilyDetails.MaritalStatus}</span>
                        </div>
                      </Grid>
                      <Grid xs={6} className={classes.padding}>
                        <div>
                          <span>{labels.messages.NA}</span>
                        </div>
                      </Grid>
                    </Grid>*/}
                  </Grid>

                </Grid>

              </>

            )
          })}
        </div>

      }
    </>)
  }
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
const mapStateToProps = state => ({
  login: state.Login.loginError,
  userDetails: state.ProfileDetails.AllProfileDetails.length == 0 ? [] : state.ProfileDetails.AllProfileDetails.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mainDetails : [],
  familyDetails: state.Login.familydetails.length == 0 ? [] : state.Login.familydetails != "NA" ? state.Login.familydetails : [],
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(MyFamilyDetailsCard))