import { Asset } from "../mainasset";
import apiurl from "../../api/url/apiurl";
import RealEstate from "../../containers/RealEstate/RealEstate";
const mutualFundApiList = [
  {
    "api": "FamilyDetails", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  {
    "api": "Dashboard", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  {
    "api": "Asset Portfolio Allocation", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: true
  },
  {
    "api": "HeaderDetails", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },

  {
    "api": "Portfolio Allocation", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "PortfolioXray", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },

  {
    "api": "ProfitLoss", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "CapitalGain", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  }
  ,
  //   {
  //   "api": "AccountStatement", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: false
  // },

  {
    "api": "SipTracker", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "AssetAllocation", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "MarketCapAllocation", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "RunningSip", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "PortfolioReport", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  }
  ,
  {
    "api": "XrayWithRedemption", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "ShortTerm", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "ELSS", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "ELSSTransaction", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "SectorAllocation", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "SplitByCategoryEquity", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "SplitByCategoryDebt", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },
  {
    "api": "Transaction", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },


]
const equityApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},

{
  "api": "ProfitLoss", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "CapitalGain", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
//   , {
//   "api": "AccountStatement", "apiResponse": false,
//   familySwitch: true, l5Switch: true, isGuestUser: false
// },
{
  "api": "MarketCapAllocation", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "PortfolioReport", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
}
  ,
{
  "api": "XrayWithRedemption", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "SectorAllocation", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "SplitByCategoryEquity", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "SplitByCategoryDebt", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},


]
const bondsApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},

{
  "api": "ProfitLoss", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "CapitalGain", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
}
  , {
  "api": "AccountStatement", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "PortfolioReport", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
}
  ,
{
  "api": "XrayWithRedemption", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "MaturityTracker", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
]
const fdApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},

{
  "api": "ProfitLoss", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "MaturityTracker", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
]
const gbApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},

{
  "api": "ProfitLoss", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
  // {
  //   "api": "CapitalGain", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: false
  // },
  // //   , {
  // //   "api": "AccountStatement", "apiResponse": false,
  // //   familySwitch: true, l5Switch: true, isGuestUser: false
  // // },
  // {
  //   "api": "PortfolioReport", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: false
  // }
  //   ,

  // {
  //   "api": "XrayWithRedemption", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: false
  // },
]
const realEstaeApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},

{
  "api": "ProfitLoss", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
]
const aifApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
  // {
  //   "api": "ProfitLoss", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: false
  // },
]
const mldApiList = [{
  "api": "PortfolioXray", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Transaction", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
  // {
  //   "api": "ProfitLoss", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: false
  // },
]
// const npsApiList = [
//   // {
// //   "api": "PortfolioXray", "apiResponse": false,
// //   familySwitch: true, l5Switch: true, isGuestUser: false
// // },
// // {
// //   "api": "Transaction", "apiResponse": false,
// //   familySwitch: true, l5Switch: true, isGuestUser: false
// // },
// // {
// //   "api": "ProfitLoss", "apiResponse": false,
// //   familySwitch: true, l5Switch: true, isGuestUser: false
// // },
// ]
// const insuranceApiList = [{
//   "api": "PortfolioXray", "apiResponse": false,
//   familySwitch: true, l5Switch: true, isGuestUser: false
// },
// {
//   "api": "Transaction", "apiResponse": false,
//   familySwitch: true, l5Switch: true, isGuestUser: false
// },
// {
//   "api": "ProfitLoss", "apiResponse": false,
//   familySwitch: true, l5Switch: true, isGuestUser: false
// },
// ]
const commonApiList = [


  {
    "api": "Dropdown", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  {
    "api": "alert", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: true
  },
  // {
  //   "api": "Track", "apiResponse": false,
  //   familySwitch: true, l5Switch: true, isGuestUser: true
  // },
  {
    "api": "BrokerList", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  // {
  //   "api": "GetAssetList", "apiResponse": false,
  //   familySwitch: false, l5Switch: false, isGuestUser: true
  // },
  // {
  //   "api": "FdrNumberList", "apiResponse": false,
  //   familySwitch: false, l5Switch: false, isGuestUser: true
  // },
  {
    "api": "AlternativeAsset", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  {
    "api": "NpsandInsuranceDropdown", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  // {
  //   "api": "realEstaeList", "apiResponse": false,
  //   familySwitch: false, l5Switch: false, isGuestUser: true
  // },
  // {
  //   "api": "buliionList", "apiResponse": false,
  //   familySwitch: false, l5Switch: false, isGuestUser: true
  // },
  {
    "api": "TransactionTypeDropdown", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: true
  },
  {
    "api": "KycData", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: true
  },
  {
    "api": "L5Report", "apiResponse": false,
    familySwitch: true, l5Switch: false, isGuestUser: true
  },
  {
    "api": "Cart", "apiResponse": false,
    familySwitch: true, l5Switch: true, isGuestUser: false
  },

  {
    "api": "RiskQuestionReport", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  {
    "api": "Plan", "apiResponse": false,
    familySwitch: false, l5Switch: false, isGuestUser: true
  },
  {
    "api": "ProfileDetails", "apiResponse": false,
    familySwitch: true, l5Switch: false, isGuestUser: true
  },
  {
    "api": "EquityProfileDetails", "apiResponse": false,
    familySwitch: true, l5Switch: false, isGuestUser: true
  }
  // {
  //   "api": "AifList", "apiResponse": false,
  //   familySwitch: false, l5Switch: false, isGuestUser: true
  // },
  // {
  //   "api": "PmsList", "apiResponse": false,
  //   familySwitch: false, l5Switch: false, isGuestUser: true
  // },
]
const tradingApiList = [{
  "api": "Upcomming SIP", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
{
  "api": "Mandate report", "apiResponse": false,
  familySwitch: true, l5Switch: true, isGuestUser: false
},
]
const color = [
  "#FF9655",
  "#DB843D",
  '#f9ad95',
  '#f78a69',
  "#B72609",
  "#f45b5b",
  "#6AF9C4",
  "#f28f43",
  "#AA4643",
  "#910000",
  "#3D96AE",
  "#DB843D",
  "#92A8CD",
  "#A47D7C",
  "#B5CA92",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#058DC7",
  "#50B432",
  "#ED561B",
  "#DDDF00",
  "#24CBE5",
  "#64E572",
  "#FFF263",
  "#6AF9C4",
];
const labels = {
  Report: {
    portfolioXray: 2,
    transaction: 3,
    sipTracker: 6,
    portfolioDetails: 8,
    afr: 9,
    xrayWithRedemption: 10,
    assetAllocation: 11,
    marketCapAllocation: 12,
    runningSip: 13
  },
  key: {
    clientIDEncryptionPassword: "WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc=",
    ivKey: "WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc=",
    apiKey: window.globalConfig.isUAT ? "L]ak;YzY!47FqLUbhcC:4b8Zaw" : "OmljOYt55bbJLgY6a7xA21rj800b6cMKOvyOJ2P8PQ8tps72dQ9271WFBo1XC1Br",
  },
  Header: {
    TrackHeader: "Your entire investment tracking in one place!",
    TrackSubHeader: "Track your financial life with a dynamic and friendly interface, give you a ",
    StrongHeader: "Complete View",
    BreakHeader: " of your investments, and advice when you need it.",
    TrackButton: "Start Tracking Now",
    totalInvestment: "Your Total Investment: ",
    ViewAnalysisButton: "View analysis",

  },
  Common: {
    rupeesymbol: "₹",
    Rupee: "\u20B9",
    close: "×",
    Blackstar: "★",
    currentValue: "Current Value ",
    DayReturn: "1 Day Return  ",
    Finnovate: `(${Asset.companyName})`,
    Important: "(Imported)",
    TrackNow: "Track Now",
    Reports: "Reports",
  },
  permission: [{ IsKYCAllowed: true, IsMyplanAllowed: true }],
  apiDetails: [{ productId: 1, product: "Mutual Fund", apiList: mutualFundApiList },

  { productId: 2, product: "Equity", apiList: equityApiList },
  { productId: 3, product: "Bonds", apiList: bondsApiList },
  { productId: 4, product: "Bullion", apiList: gbApiList },
  { productId: 5, product: "FD", apiList: fdApiList },
  { productId: 6, product: "AIF", apiList: aifApiList },
  { productId: 7, product: "Real Estate", apiList: realEstaeApiList },
  { productId: 0, product: "CommonAPI", apiList: commonApiList },
  { productId: 8, product: "NPS", apiList: [] },
  { productId: 10, product: "MLD", apiList: mldApiList },
  { productId: 11, product: "Trade", apiList: tradingApiList },
  ],
  accountDetails: {
    AccountDetails: "Account Details",
    ModeofHoldings: "Mode of Holdings",
    TaxStatus: "Tax Status",
    MemberSince: "Member Since",
    Gender: "Gender",
    Nationality: "Nationality",
    JointHolder: "Joint Holder",
    Name: "Name",
    PAN: "PAN",
    MobileNo: "Mobile No",
    UCCDetails: "UCC Details",
    EquityAccount: "Equity Account",
    DateofBirth: "Date of Birth",
  },

  dematDetails: {
    EquityAccount: "Equity Account",
    DpDetails: "Dp Details",
    DpID: "DP ID",
    Relationship:'Relationship',
    ClientID: "Client ID",
    Depository: 'Depository',
    NomineeDetails: "Nominee Details",
    Nominee: "Nominee",
    NomineeRelationship: "Nominee Relationship",
    PercentageAllocation: "Percentage Allocation",
    NomineeName: "Nominee Name"
  },
  //  : [{ productId: 1, product: "Mutual Fund", apiList: mutualFundApiList },

  // { productId: 2, product: "Equity", apiList: equityApiList },
  // { productId: 3, product: "Bonds", apiList: bondsApiList },
  // { productId: 4, product: "GB", apiList: gbApiList },
  // { productId: 5, product: "FD", apiList: fdApiList },
  // { productId: 6, product: "AIF", apiList: aifApiList },
  // { productId: 9, product: "NPS", apiList: npsApiList },  
  // { productId: 10, product: "Insurance", apiList: insuranceApiList },
  // { productId: 0, product: "CommonAPI", apiList: commonApiList },
  // { productId: 11, product: "Trade", apiList: tradingApiList },
  // ],
  AccountType: [
    {
      label: "Savings Account",
      value: 1,
    },
    {
      label: "Current Account",
      value: 2,
    },
  ],
  transactionType: [
    { label: "One Time", value: 2 },
    { label: "SIP(Monthly)", value: 3 },
  ],
  chartColor: color,
  schemeColors: [
    // Google color code
    "#C61E34", '#AA4643', "#DA251C", "#ED1C22"
    , "#B02A2F", '#A47D7C', "#AE275F", '#c42525',
    '#843b62', "#013480", '#621940', '#ffb997',
    "#ea4c89", "#006497", '#A47D7C', '#DB843D',
    '#ED561B', '#1166DD', '#FF9655', "#f7a35c",
    "#f45b5b", "#434348", "#FF9F1A", "#f15c80",
    "#00AAFF", '#92A8CD', '#20639B', '#4572A7'
    , '#f67e7d', '#058DC7', '#FFF263', "#e4d354"

    , "#FFD500", "#91e8e1", "#30BFBF", '#DDDF00',
  ],
  TradingType: [
    { label: "Amount (Rs)", value: 1 },
    { label: "Custom No. of Units", value: 2 },
    { label: "All Available No. of Units", value: 3 },
    // { "label": "All Exit Load Free Units", "value": 4 },
    { label: "All Short Term Gain Units", value: 5 },
    { label: "All Long Term Gain Units", value: 6 },
  ],
  Mandate: {
    Account: "BSE MF Account",
    Bank: "Bank",
    Mandate: "Mandate Type",
    MandateAccount: "Mandate"
  },

  SWPtype: [
    { label: "Monthly", value: 2 },
    { label: "Weekly", value: 1 },
    { label: "Quarterly", value: 3 },
  ],
  dynamicRow: {
    typeScripName: "Type Scrip Name"
  },
  epf: {
    uanNumber: "UAN Number",
    forgotUanNumber: "Forgot UAN Number?",
    continue: "CONTINUE",
    addEpf: "Add Epf Information",
    epfDetails: "Epfo details",
    uanDescription: `You need to enter UAN number and click on `,
    otpVerification: "otp verification",
    otpDescription: "You need to enter OTP received on ",
    enterOTP: ". Enter 6-digit OTP below",
    otp: "OTP",
    verify: "VERIFY",
    inputSuccessful: "Input successful. Fetching Epfo Details",
    requestAccepted: "Your request has been accepted",
    processingYourRequest: "Processing Your Request",
    successfullyUpdated: "Successfully Updated"
  },
  messages: {
    Dataempty: "No Data Available.",
    notification: "Notifications",
    placeOrderMsg: "Are you sure want to confirm the order?",
    subject: "Subject",
    allAlerts: "All Alerts",
    Epf: "EPF",
    amountZeroPlaceholder: "0",
    invDate: "Inv Date",
    invDatePlaceholder: "Select Inv Date",
    type: "Type",
    deleteMsg: "Are you sure want to delete?",
    emailIDNotAvailable: "Email is not available",
    submit: "Submit",
    loading: "Loading...",
    select: "Select",
    MandateTypeempty: "Kindly select mandate type",
    searchStocks: "Enter 3 or more characters to search stocks",
    InvalidMandateID:
      "Your status is not approved, please select another mandate",
    Bankempty: "Kindly select your bank",
    Amountempty: "Kindly provide amount",
    minimumAmount: "Amount should be minimum ₹1,000",
    Amount: "Amount should not be zero",
    InvalidEmail: "Invalid Email",
    MaximumEmail: "Maximum Length Exceed",
    selectMandateType: "Select Type",
    selectAccount: "Select Account",
    selectBank: "Select Bank",
    //  InvalidPassword: "",
    InvalidName: "Invalid Name",
    InvalidPAN: "Invalid PAN",
    MinimumPasswordError: "Password minimum length should be 7 digit",
    passwordmismatch: "New password and Confirm password does not match",
    NA: "NA",
    apierror: "No response from server, please try again after sometime.",
    uccnotavailble: "Please Create UCC to continue with Buy/Sell Mutual funds",
    kycnotverifiedmessage: "KYC not Verified",
    grandtotal: "Grand Total",
    required: "Required",
    allocationErrorMsg: "Please enter valid allocation percentage",
    schemenodataavaible: "SIP and One Time not allowed in this scheme",
    networkerror: "Please Check Your Internet Connection",
    schemeerror: "Scheme Not Allowed",
    bankrequired: "Please Select Bank",
    Ifscerror: "Invalid IFSC code",
    Zerovalidation: "Cannot proceed with 0",
    warning: "warning",
    error: "error",
    PoweredBy: `© Powered by FINNTRACK`,
    mismatch: "Account Number mismatched",
    markasdefault: "Mark as default",
    YesButton: "Yes",
    Nobutton: "No",
    saveButton: "Save",
    cancel: "Cancel",
    Error: "Atleast one field should have greater than zero",
    transact: "Transaction added successfully",
    saveAddMoreButton: "Save and Add More",
    gst: "Please enter a valid GST",
    select: "Select",
    transactionSucessMessage: "Transaction added successfully",
    transactionUpdatedMessage: "Transaction Updated Successfully",
    AssetSuccess: "Asset Name Added  successfully",
    mandateMessage:
      "If mandate is not approved, you can only setup SIPs with first order today. Please go back and modify the order.",
  },
  DownloadLabel: {
    portfolioxray: "PortFolio X-Ray Report.",
    transaction: "Transaction Report.",
    capitalgainaccountstatement: "Account Statement Report.",
    capitalgainvaluationreport: "Valuation Report.",
    mainreportcapitalgain: "Capital Gain Report.",
    capitalgaindividend: "Dividend Report.",
    siptracker: "SIP Tracker Report.",
    accountstatement: "Account Statement Report.",
    portfoliodetails: "Portfolio Details Report.",
    shortterm: "Short Term /Long Term Taxation Report.",
    exitload: "Exit Load Report.",
    Elss: "ELSS Report.",
    xraywithredemption: "X-Ray With Redemption Report.",
    xrayWithSoldUnits: "X-Ray With Sold Units Report.",
    assetallocation: "Asset Allocation Report.",
    margetcapallocation: "Marget Cap Allocation Report.",
    runningsip: "Runinng SIP Report.",
  },
  apiKey: {
    apiKeys: window.globalConfig.isUAT ? "L]ak;YzY!47FqLUbhcC:4b8Zaw" : "OmljOYt55bbJLgY6a7xA21rj800b6cMKOvyOJ2P8PQ8tps72dQ9271WFBo1XC1Br",
  },
  StocksandTrade: {
    stocks: " Stocks",
    fd: " FDs",
    tradedbond: " Tradable Bonds",
    Debentures: " Debentures",
    finvat: `${Asset.companyName}:`,
    date: "Date",
    broker: "Broker",
    contractNo: "Contract Note",
    placeholderContractNo: "Enter Contract Note",
    settlementNo: "Settlement Number",
    placeholderSettlementNo: "Enter Settlement Number",
    Bonds: "Bonds",
    STT: "STT",
    GST: "GST / S.Tax",
    stampChanges: "Stamp Charges",
    transactionChanges: "Transaction Charges",
    otherChanges: "Other Charges",
    totalamount: "Total Amount",
    addRow: "Add Row",
    sale: "Sale",
    saleAmount: "Sale Amount",
    saleQTY: "Sale Quantity",
    salePrice: "Sale Price",

    brokerage: "Brokerage",
    selectDate: "Select Date",
    purchase: "Purchase",
    assetname: "Asset Name",
    purchaseQty: "Purchase Quantity",
    purchasePrice: "Purchase Price",
    purchaseAmount: "Purchase Amount",


  },
  Nps: {
    currentPensionFundManager: "Current Pension Fund Manager",
    currentPensionFundManagerPlaceholder: "Select Current Pension Fund Manager",
    pranNo: "PRAN No / Policy No",
    pranNoPlaceholder: "Enter PRAN No / Policy No",
    addNpsInformation: "Add NPS Information",
    transaction: "Transaction",
    allocationUnits: "Allocation Units",
    allocationNav: "Allocation Nav",
    valuationNav: "Latest Nav",
    amount: "Amount",
    amountPlaceholder: "0",
    transactionType: "Transaction Type",
    transactionTypePlaceholder: "Select Transaction Type",
    investmentDate: "Investment Date",
    investmentDatePlaceholder: "Select Inv Date",
    valuation: "Valuations",
    equity: "E",
    commodity: "C",
    gold: "G",
    other: "A",
    latestValue: "Latest Value",
    addNps: "Add NPS",
    latestValuePlaceholder: "Enter Latest Value",
    latestValueDate: "Latest Value Date",
    latestValueDatePlaceholder: "Select Latest Value Date",
    addTransaction: "Add Transaction",
    equityFullForm: "Equity",
    commodityFullForm: "Commodity",
    goldFullForm: "Gold",
    othersFullForm: "Others",
    save: "Save",
    saveAndMore: "Save & More",
    confirmation: "Confirmation",
    assetDeleteMsg: "Are you sure want to delete this asset ?",
    transactionDeleteMsg: "Are you sure want to delete this transaction ?",
    inceptionDate: "Inception Date",
    inceptionDatePlaceHolder: "Select Inception Date",
    valuationUnit: "Latest Units",
    latestValue: "Lastest Value",
    latestValuePlaceHolder: "Enter Lastest Value",
    nomineeDetails: "Nominee Details",
    nomineeType: "Nominee Type",
    nomineeTypePlaceHolder: "Select Type",
    nomineeName: "Nominee Name",
    nomineeNamePlaceHolder: "Enter Name",
    nomineeRelationship: "Nominee Relationship",
    nomineeRelationshipPlaceHolder: "Nominee Relationship PlaceHolder",
    nomineeDOB: "Nominee DOB",
    nomineeDOBPlaceHolder: "Enter DOB",
    nomineepercentage: "Nominee Percentage",
    nomineepercentagePlaceHolder: "Enter Percentage",
    isMinor: "Minor",
    guardianName: "Guardian / Father Name",
    guardianNamePlaceHolder: "Enter Name",
    addNominee: "Add Nominee",
  },
  PmsAndAif: {
    assetName: 'Asset Name',
    valuation: "Valuation",
    schemeName: 'Scheme Name',
    broker: 'Broker',
    brokerCode: 'Broker Code',
    folioNo: 'AMC Ref No',
    assetClass: 'Asset Class',
    transactionType: 'Type',
    aifCategory: "AIF Category",
    investmentDate: 'First Investment Date',
    commitmentAmount: 'Commitment Amount',
    paidUpAmount: 'Invested Amount',
    pendingCommitment: 'Pending Commitment',
    currentValue: 'Current Value',
    lastEvaluationDate: 'Last Evaluation Date',

    assetNamePlaceholder: 'Select Asset Name', schemeNamePlaceholder: 'Select Scheme Name',
    brokerPlaceholder: 'Select Broker',
    brokerCodePlaceholder: 'Enter Broker Code',
    folioNoPlaceholder: 'Enter AMC Ref No',
    aifCategoryPlaceholder: "Select AIF Category",
    amountZeroPlaceholder: "0",
    assetClassPlaceholder: 'Select Asset Class',
    invDate: "Inv Date",
    select: "Select",
    investmentDatePlaceholder: 'Select Investment Date',
    commitmentAmountPlaceholder: 'Enter Commitment Amount',
    amountPlaceholder: 'Enter Amount',
    transactionTypePlaceholder: "Select Transaction Type",
    paidUpAmountPlaceholder: 'Enter Paid Up Amount',
    pendingCommitmentPlaceholder: 'Enter Pending Commitment',
    currentValuePlaceholder: 'Enter Current Value',
    lastEvaluationDatePlaceholder: 'Select Last Evaluation Date',
    header: 'Committed Instalments',
    transactionHeader: 'Transaction',
    list: [{ label: 'date', }, { label: 'amount' }],
    transactionList: [{ label: 'date', }, { label: 'type' }, { label: 'amount' }],

    confirmationText: "Are you sure want to delete this asset ?",
    date: "Date",
    datePlaceholder: "Select Date",
    amount: "Amount",
    addTransaction: "Add Transaction",
    save: "Save",
    saveAndMore: "Save & More",
    newScheme: "New Scheme",
    select: "Select",
    selectIssuer: "Select Issuer",
    issuer: "Issuer",
    schemeName: "Scheme Name",
    enterSchemeName: "Enter Scheme Name",
    add: "Add",
    cancel: "Cancel"
  },
  RealEstate: {
    propertyName: 'Property Name',
    AssetName: "Asset Type",
    propertyType: 'Property Type',
    addBullion: 'Add Gold / Silver',
    addRealEstate: 'Add Real Estate',
    BulionType: 'Type',
    BuyPrice: "Buy Price",
    BuyDate: 'Buy Date',
    placeHolderBuyDate: "Select Buy Date",
    InvestedValue: 'Invested Value',
    InvestedDate: 'Invested Date',
    PlaceHolderInvestedDate: 'Select Invested Date',
    Location: 'Location',
    LocationPlaceHolder: " Enter Location",
    Usage: "Usage",
    UsagePlaceHolder: "Select Primary|Rented|Vacent",
    PropertySize: 'Property Size (Sq. Unit)',
    PropertySizePlaceHolder: 'Enter Size',
    Valuation: 'Valuation',
    latestValue: "Latest Value",
    latestValueDate: 'Latest Value Date',
    PlaceHolderlatestValueDate: 'Select Latest Value Date',
    RealEstateconfirmationSellText: "Are you sure want to sell the property ?",
    BullionconfirmationSellText: "Are you sure want to sell the gold/silver ?",
  },
  riaPlanComplete: {
    TransactionNumber: "Transaction Number",
    AmountPaid: "Amount Paid",
    Bank: "Bank",
    InvoiceHasBeenSent: "Invoice Will be sent to your registered Email-Id after settlement of Payment.",
    Dashboard: "Dashboard",
    inVoice: "View Invoice",
    youHaveSubscribed: "You have subscribed for",
    planDescription: "Plan Description",
    subscriptionPlan: " A Subscription plan that suits all",
    financialPlan: "Financial Planning",
    equityAdvisory: "Equity Advisory",
    allInOne: "All in One",
    billMonthly: "Bill Monthly",
    billSixMonth: "Bill every 6 months",
    subscribe: "Subscribe",
    xpertAdvisory: "Prime X- A to Z Xpert Advisory",
    helpDescription: " Our experts help you set the right foundation and processes required to build your long-term equity portfolio.",
    proFeatures: "Pro features are available with Financial Planning + Equity Advisory",
    financialPlanDescription: " A Financial plan that encompasses the five main pillars of personal finance-risk management, financial goals, wealth management,taxation and wealth succession."
  },
  PurchasedPlan: {
    Invoice: "Invoice",
    yearlySubscription: "Yearly Subscription",
    startedOn: "Started On",
    renewalDate: "Renewal Date",
    status: "Status",
    cancel: 'Cancel',
    autoCharge: 'Auto Charge',
    enable: 'Enable',
    myPlan: "My Plan",
    activeSubscriptions: "Active Subscriptions",
    subscriptionHistory: "Subscription History",
    download: "Download",
    proceed: "Proceed"
  },
  advisory: {
    payment: 'Payment',
    amountoBePaid: 'Amount to be Paid',
    includingGST: 'including GST',
    modeOfPayment: 'Mode of Payment',
    online: 'Online',
    offline: 'Offline',
    selectBank: 'Select Bank',
    searchBankName: 'Search Bank Name',
    addNewBankAccount: 'Add new bank account',
    doYouHaveGST: 'Do you have a GST number',
    yes: "Yes",
    no: "No",
    gstNumber: "GST Number:",
    enterGSTNumber: "Enter GST Number",
    billingAddress: "Billing Address:",
    of: "of",
    fifteen: "15",
    submit: "Submit"
  },
  stepper: {
    Continue: "Continue",
    advisoryAccountDetails: "Advisory Account Details",
    investmentAdvisoryDetails: "Investment Advisory Agreement",
    continueToPayment: "Continue to Payment",
    PlanDetails: "Plan Details",
    PlanName: "Plan Name",
    ChangePlan: "Change plan",
    ViewSign: "View & Sign",
    Signed: "Signed",
    ViewAllSignedAgreements: "View all signed agreements",
    SignDate: "Sign Date",
    PrimaryHolder: "Primary Holder",
    FullName: "Full Name",
    EmailID: "Email ID",
    Mobile: "Mobile",
    PreliminaryCheck: "Preliminary Check",
    KYCSTATUS: "KYC STATUS",
    Verified: "Verified",
    notVerified: "NOT VERIFIED",
    VerifyNow: "Verify Now",
    RISKPROFILE: "RISK PROFILE",
    NOTCOMPLETED: "NOT COMPLETED",
    AssessNow: "Assess Now",
    BANKDETAILS: "BANK DETAILS",
    AddBank: "Add Bank",
    ICICIBANK: "ICICI BANK",
    NA: "NA",
  },

  tradingTransaction: {
    InvestmentSuccessful: "Investment Successful",
    InvestmentPending: "Investment Pending",
    InvestmentCancel: "Investment Cancel",
  },

  headerBackdround: {
    Summary: "Summary",
  },

  sideMenu: {
    Expired: "Expired",
    Login: "UNLOCK",
    Yoursessionisexpired: '"Your session is expired ....please login again"',
  },

  accountDetails: {
    AccountDetails: "Account Details",
    ModeofHoldings: "Mode of Holdings",
    TaxStatus: "Tax Status",
    MemberSince: "Member Since",
    Gender: "Gender",
    Nationality: "Nationality",
    JointHolder: "Joint Holder",
    Name: "Name",
    PAN: "PAN",
    DateofBirth: "Date of Birth",
  },

  bankDetails: {
    BankDetails: "Bank Details",
    BankAccountType: "Bank Account Type",
    AccountNumber: "Account Number",
    IFSCCode: "IFSC Code",
    BranchName: "Branch Name",
    MandateID: "Mandate ID",
    DownloadMandate: "Download Mandate",
    ApproveMandate: "Approve Mandate",
    Amount: "Amount",
    CreatedOn: "Created on",
    Status: "Status",
    AddNewBank: "Add New Bank",
    IKnowBankIFSCCode: "I Know Bank IFSC Code",
    SelectBankAccountType: "Select Bank Account Type",
    EnterAccountNumber: "Enter Account Number",
    EnterIFSCCode: "Enter IFSC Code",
    IFSC: "IFSC",
    SelectBankName: "Select Bank Name",
    searchBankName: "Search Bank Name",
    searchBranchName: "Search Branch Name",
    enterThreeOrMoreChar: "Enter 3 or more character to search branch name",
    BankName: "Bank Name",
    SelectBranch: "Select Branch",
    Branch: "Branch",
    AddressLine: "AddressLine",
    ReEnterAccountNumber: "Re-Enter Account Number",
    EnterAccountNo: "Enter Account No",
    EnterReEnterAccountNo: "Enter Re-Enter Account No",
    Cancel: "Cancel",
    AddBank: "Add Bank",
    yes: "Yes",
    no: "No"
  },
  profile: {
    investor: "Investor",
    self: "self",
    ucc: "UCC:",
    accountsetup: "Your account is not yet setup",
    fullname: "Full Name",
    pending: "Pending",
    EmailID: "Email ID",
    Mobile: "Mobile",
    DOB: "DOB",
    Gender: "Gender",
    Maritalstatus: "Marital Status",
    Kyc: "KYC",
    pan: "PAN ",
    ipvstatus: "IPV Status",
    Occupation: "Occupation ",
    Incomerange: "Income Range ",
    Fathersname: "Father's / spouse Name ",
    Mothersname: "Mother's Name ",
    COB: "City of Birth ",
    Citizenship: "Citizenship ",
    taxresidency: "Tax Residency ",
    residentialstatus: "Residential Status ",
    PoliticalStatus: "Politically Exposed ",
    wealth: "Wealth Source ",
    Address: "Addresses",
    Document: "Documents",
    Residentialaddress: "Residential Address",
    correspondanceaddress: "Correspondance Address",
    createnow: "Create Now",
    photo: "Photo",
    pancard: "PAN Card",
    AddressProof: "Address Proof",
    signature: "Signature",
    schedulenow: "Schedule Now",
    status: "Not started",
    uploadnow: "Not Upload",
    uploaded: "Uploaded",
    noducment: "No Document",
    aadhar: "Aadhar Card",
  },
  personalIncome: {
    PersonalIncomeDetails: "Personal & Income Details",
    Nationality: "Nationality",
    CountryofBirth: "Country of Birth",
    PlaceofBirth: "Place of Birth",
    Occupation: "Occupation",
    AnnualIncome: "Annual Income",
    PoliticalStatus: "Political Status",
    EnterNationality: "Enter Nationality",
    EnterCountryofBirth: "Enter Country of Birth",
    EnterPlaceofBirth: "Enter Place of Birth",
    EnterOccupation: "Enter Occupation",
    EnterAnnualIncome: "Enter Annual Income",
    EnterPoliticalStatus: "Enter Political Status",
    AreYouTaxResident:
      "Are you tax resident in any other country than india?",
    TaxCountry: "Tax Country",
    TaxIdentificationType: "Tax Identification Type",
    TaxIdentificationNumber: "Tax Identification Number",
    EnterCountryName: "Enter Country Name",
    EnterTaxIdentificationType: "Enter Tax Identification Type",
    EnterTaxIdentificationNumber: "Enter Tax Identification Number",
    Cancel: "Cancel",
    AddCountry: "Add Country",
    AddTaxPayingCountry: "Add Tax Paying Country",
    Edit: "Update",
  },

  nomineeDetails: {
    NomineeDetails: "Nominee Details",
    Therecanbe3Nominees:
      "There can be 3 Nominees with % allocation of up to 100%",
    NomineeName: "Nominee Name",
    Nominee: "Nominee",
    Nomineerelationship: "Nominee Relationship",
    Allocation: "% Allocation",
    NomineeGuardianName: "Nominee's Guardian's Name",
    Youhavenotadded: "You have not added the nominee.",
    NomineeisMajor: "Nominee is Major",
    Enternameofnominee: "Enter name of nominee",
    SelectRelationship: "Select Relationship",
    HoldersrelationshipwithNominee: "Holder's relationship with Nominee",
    EnterAllocation: "Enter % Allocation",
    EnterGuardiansName: "Enter Guardian's Name",
    Cancel: "Cancel",
  },

  userCard: {
    KYCVerified: "Account Active",
    KYCNotVerified: "Account Not Active",
    PrimaryInvestor: "Primary Investor",
    PANasperKYC: "PAN (as per KYC)",
    DateofBirth: "Date of Birth",
    MobileNumber: "Mobile Number",
    EmailLoginid: "Email (Login id)",
    Address: "Address",
    FamilyLinkage: "Family Linkage",
    PAN: "PAN",
    syncToolTip: "Click here to refresh profile details"
  },

  PortfolioReport: {
    type: "Txn Type",
    date: "Txn Date",
    units: "Units",
    quantity: "QTY",
    pureNav: "Pur.Nav(₹)",
    purePrice: "Pur.Price(₹)",
    gross: "Gross",
    currentamt: "Curr.amt(₹)",
    profitloss: "gain/loss(₹)",
    PRICE: "PUR.PRICE(₹)",
    divpay: "Div.pay(₹)",
    int: "Int ($)",
    divreimv: "DIV RE-IMV",
    grossPurPrice: "Pur.Amt(₹)",
    divrev: "Div.REIN",
    netG: "NET GAIN/",
    loss: "LOSS(₹)",
    day: "DAYS",
    absret: "ABS.Ret(%)",
    age: "(% age)",
    xirr: "Xirr(%)",
    folio: "Folio No",
    netgainloss: "Net Gain/Loss",
  },
  AFRreport: {
    short: " short term holding",
    long: "long term holding",
    total: "total holding",
    schemeName: "Scheme Name",
    scripName: "Scrip Name",
    topheaderexitload: "Units With Exit Load",
    Units: "Units",
    Quantity: "QTY",
    quantity: "QTY",
    InvestedAmount: "Inv.",
    current: "Curr.Amount",
    Gainloss: "Gain loss",
    category: "Category",
    totalunits: "Total units",
    Purchase: "Purchase",
    amount: "Amount",
    value: "Value",
    Marget: "Market",
    gl: "Gain / loss ",
    details: "Exit Load Details",
    grandtotal: "Grand Total",
    investmentDate: "investment date",
    maturitydate: "maturity Date",
    investmentAmount: "Investment Amount",
    presentvalue: "Present Value",
  },
  xraywithredemption: {
    Balancedunits: "Balance Units",
    BalancedQuantity: "Balance Quantity",
    Redeemedunits: "Redeemed Units",
    RedeemedQuantity: "Sold Quantity",
    total: "total",
    SchemeName: "Scheme Name",
    units: "Units",
    Quantity: "QTY",
    scripName: "Scrip Name",
    quantity: "QTY",
    currentvalue: "current value /",
    Gainloss: " GainLoss",
    Invested: "Invested",
    value: "Value",
    salevalue: "Sale Value",
    Realised: "Realised GainLoss",
    value2: "value/",
    grandtotal: "Grand Total",
    XrayTotal: "Total",
    InvestedValue: "INVESTED VALUE",
    CurrentValue: "CURRENT VALUE",
    GainLoss: " GAINLOSS",
    amount: "amount",
    current: "current",
    redemption: "Redemption",
  },
  Accountstatement: {
    Date: "TXN Date",
    Txntype: "TXN Type",
    Txnnature: "TXN Nature",
    Amount: "Amount",
    units: "UNITS",
    Quantity: "QTY",
    quantity: "QTY",
    Nav: "Nav",
    Price: "Price",
    Balunits: "Balance Units",
    Balquantity: "Balance QTY",
    TxntypeMobile: "TRANSACTION TYPE",
    Amountmobile: "AMOUNT",
    unitsmobile: "UNITS",
    folio: "Folio No : ",
    contractno: "Contract Number :",

  },
  session: {
    welcomehai: "Hi",
    SessionExpieed: "Your session is expired",
    SessionExpiredMsg: 'Due to inactivity your session has timed out,',
    PasswordEnter: "please enter your password to continue.",
    Not: "Not",
  },
  Trading: {
    Allocation: "Allocation",
    EquitySectorAllocation: "Equity Sector Allocation",
    Return: "Return",
  },
  capitalGain: {
    FinancialYear: "FINANCIAL YEAR",
    Accountstatement: "ACCOUNT STATEMENT",
    valuationreport: "VALUATION REPORT",
    capitalgains: "CAPITAL GAINS",
    DividendReport: "DIVIDEND REPORT",
  },
  siptracker: {
    Schemename: "SCHEME NAME",
    scripname: "SCRIP NAME",
    startdate: "START DATE",
    sipamount: "SIP Amount",
    Frequency: "FREQUENCY",
    sip: " Instalment ",
    received: "Received",
    missed: " Missed",
    Currentvalue: "CURRENT VALUE",
  },
  MFTransaction: {
    DATE: "DATE",
    SCHEMENAME: "SCHEME NAME",
    AMOUNT: "AMOUNT",
    TRANSACTIONTYPE: "TRANSACTION TYPE",
    STATUS: "STATUS",
  },
  Login: {
    Continue: "Continue",
    Signin: "Login",
    MemberText: "Members LOGIN TO ACCESS",
    FinnTrackText: " FinnVest & FinnTrack",
    FINOVATE: Asset.companyName,
    Welcome: "Welcome to",
    JADE: Asset.companyName,
    congratulations: "Congratulations",
    successMsg: "Your account has been created successfully",
    clickHereToLogin: "Click here to Login",
    redirecting: "Redirecting in",
    sec: "secs...",
    email: "Email",
    password: "Password",
    forgotPasswordToolTip: "Please enter email for this action",
    Signincontinue: "Please sign in to continue",
    DownloadMobileApp: "Download Mobile App",
    Signup: "Sign Up",
    ForgetPassword: "Forgot Password",
    welcome: "Welcome!",
    joinUs: "Join us and set your ",
    subText: "path towards Financial Freedom",
    invest: "We are a SEBI registered investment advisor",
    // firstLabel: "",
    // secondLabel: "",
    // thirdLabel: "",""
    ConfirmOTP: "Confirm OTP",
    EnterOTP: "Enter OTP",
    DidntreceivetheOTPyet: "Didn't  receive  the  OTP  yet ?",
    Signinwithotp: "Sign in With OTP",
    Signinwithpassword: "Sign in With Password",
    Firsttimeuser: "First time user?",
    finnFitAccountText: "Don’t have FinnFit account? ",
    firstLabel: Asset.companyName == "Finnovate" ? "INA100013518" : "",
    secondLabel: Asset.companyName == "Finnovate" ? "ARN - 52128" : "",
    thirdLabel: Asset.companyName == "Finnovate" ? "BSE - 17548" : "",
    endState: `${Asset.companyName} is safe and secure`,
  },
  Signup: {
    Name: "Name",
    setpassword: "Set Password",
    confirmpassword: "Confirm Password",
    Email: "Email",
    Mobile: "Mobile Number",
    Pan: "PAN",
    Emailotp: "Email OTP",
    Mobileotp: "Mobile OTP",
    submit: "Submit",
    verify: "Verify",
    changeemail: "Change Email",
    changemobilenumber: "Change Mobile Number",
    byClickingSubmit: "By clicking Submit, you agree to our ",
    termsAndCondition: "Terms & Conditions",
    resendOTP: "Resend OTP",
    welcome: "Welcome"
  },
  schemes: {
    SubscriptionType: "Subscription Type",
    investmentStrategy: "Investment Strategy",
    investmentAmount: "Investment Amount",
    volatility: "Volatility",
    select: "Select",
    oneWeek: "1W",
    OneYear: "1Y",
    threeYear: "3Y",
    AUM: "AUM (Rs. In Cr.)",
    OneTime: "One Time",
    startsip: "Start SIP",
    Risk: "Risk",
    search: "Search",
    enterYourSearch: "Enter Your Search",
    category: "Category",
    allCategory: "All Category",
    riskType: "Risk Type",
    allRiskProfiles: "All Risk Profiles",
    availableToInvest: "Available to Invest",
    all: "All",
    amc: "AMC",
    searchMutualFundCompanies: "Search Mutual Funds Companies",
    kycnotverifiedmessage: "Your KYC is not completed... Please complete kyc to continue",
    fiveThousand: "₹ 5,000",
    tenThousand: "₹ 10,000",
    fifteenThousand: "₹ 15,000",
    continue: "Continue",
    fiveHundred: "₹ 500",
    twoThousand: "₹ 2,000",
    thousand: "₹ 1,000", selectSIPDate: "Select SIP start date"
  },
  tableHeader: {
    AccountNumber: "Account Number",
    Ifsc: "IFSC",
    AccountHolderName: "Account HolderName",
    Amount: "Amount",
    Actions: "Actions",
  },
  currency: {
    Rupee: "\u20B9 ",
    close: "×",
    rupeesymbol: "₹",
    Blackstar: "★",
  },
  ProfitLoss: {
    SchemeName: "Scheme Name",
    scripName: "SCRIP NAME",
    Units: "Units",
    Size: "Size",
    quantity: "QTY",
    PurchaseValue: "Purchase Amount",
    investedAmount: "Invested Amount",
    GainValue: "Booked G/L",
    SellValue: "Sale Amount",
    redeemAmount: "Redeem Amount",
    DividendValue: "Dividend",
    Interest: "Interest",
    Total: "Grand Total",
  },
  userTableHeader: {
    userid: "UserID",
    id: "ID",
    title: "Title",
    completed: "Completed",
  },
  pageHeader: {
    title: "Sample Page",
    userTitle: "Users Details",
  },
  summaryPage: {
    splitByInstrument: "Split by Category ",
    splitByMarketCap: "Split by Market Cap ",
    EquityFundSector: "Equity Fund Sector Analysis Allocation",
    EquitySector: "Equity Sector Analysis Allocation",
    CategoryAllocation: "Category Allocation",
    Category: "Category",
    Asset: "Asset",
    AssetPercentage: "Asset %",
    holding: "Holding %",
    Amount: "Current Value",
    categoryPercentage: "Category (%)",
    holdingPercentage: "Holding (%)",
    totalunits: "Total Units",
    sip: "SIP Amount",
    date: "Start/End Date",
    Puramount: "Pur.Amount",
    Divamount: "Div.Amount",
    Curamount: "Curr.Amount",
    CurrentNav: "Curr.Nav",
    PurNav: "Avg.Nav",
    small: "Small Cap Amount",
    mid: "Mid Cap Amount",
    large: "Large Cap Amount",
    other: "Others",
    equity: "Equity",
    dept: "DEPT",
    gold: "Gold",
    others: "Others"
  },
  portfolio: {
    title: "Portfolio Allocation",
    InvestedValue: "INVESTED AMOUNT",
    MargetValue: "CURRENT VALUE",
    Assest: "PRODUCT",
    Unrealized: "NET G/L",
    Equityfunds: "Equity Funds",
    Deptfunds: "Debt Funds",
    Balancedfunds: "Balanced Funds",
    Taxfunds: "Tax Funds",
    description: " ",
  },
  menu: {
    explorepage: "Explore",
    getadvice: "GET ADVICE",
    portfolio: "PORTFOLIO",
    tabname: "Portfolio",
    members: "Members",
    noMembers: "No Member Found",
    mutualFunds: "Mutual Funds",
    epf: "EPF",
    liquiloans: "Liquiloans",
    Equity: "Equity",
    track: "Track",
    back: "Back",
    explore: "BUY / SELL",
    Dashboard: "Dashboard",
    FamilyReport: "Family Report",
    track: "Track",
    getadvice: "GET ADVICE",
    portfolio: "PORTFOLIO",
    tabname: "Portfolio",
    transactionorder: "Transaction Order",
    trading: "Mutual Funds Trading",
    transact: "Transact",
  },
  notificationBlock: {
    startingKYC: "Starting KYC Verification Process",
    ensureDocument: "Ensure you keep below mentioned document ready for smooth onboarding.",
    documentFormat: "All the documents be saved in image format .jpeg, .png"
  },
  categoryallocation: {
    equity: "Equity",
    debt: "Debt",
    hybrid: "Hybrid",
  },
  profitlossfilter: {
    Filterby: "Filter by",
    Schemes: "Schemes",
    TransactionType: "Transaction Type",
    AssetClass: "Asset Class",
    DateRange: "Date Range",
  },
  block: {
    head: "Explore Funds",
    paragraph: "",
  },
  sidemenu: {
    portfolio: "Portfolio",
    reports: "Reports",
    MFtrading: "MF Trading",
    Product: "Product",
    epf: "EPF",
    transactions: "Transact",
    MutualFunds: "Mutual Funds",
    Equity: "Stocks / ETF",
    Bonds: "Tradable Bonds",
    FD: "Fixed Income",
    RealEstate: 'Real Estate',
    Gold: "Gold / Silver",
    AIF: "AIF / PMS",
    NPS: "NPS",
    Insurance: "Insurance",
    Structured: "Structured Products",
    GoldBonds: "Gold / Silver",
    Trading: "MF Trading",
    Summary: "Summary",
    portfolioxray: "Portfolio X-Ray",
    Transaction: "Transaction",
    profitloss: "Profit & Loss",
    Taxationreport: "Taxation Report",
    siptracker: "SIP Tracker",
    Accountstatement: "Account Statement",
    Portfolioreport: "Portfolio Details",
    AFRreport: "AFR Report",
    xraywithredemption: "X-Ray With Redemption",
    margetcapallocation: "Market Cap Allocation",
    RunningSip: "Running SIP Summary",
    Downloadreport: "Download Report",
    Assetallocation: "Asset Allocation",
  },
  ProfileSideMenu: {
    profile: "My KYC",
    myfamily: "My Family",
    myaccount: "My Accounts",
    riskprofile: "Risk Profile",
    myplan: "My Plans",
    myfinancial: "My Financial Plan",
  },
  TradeSideMenu: {
    Stocks: "Stocks",
    Tradebond: "Tradable Bonds",
    fd: "FDs",
    Debentures: "Debentures",
    Bonds: "Bonds"
  },
  //profile plan label
  profilePlan: {
    xpert: "Xpert",
    xpertplus: "Xpert Plus",
    xpertpremium: "Xpert premium",
    financialplanningplans: "Financial Planning Plans",
    premiumplan: "Premium Plan: ",
    equityadvisoryplans: "Equity Advisory Plans",
    stocksip: "Stock SIP",
    stockplus: "Stock Plus",
    stockpremium: "Stock Premium",
    Bonds: 'Bonds'
  },
  myPlan: {
    Subscription: "Subscription",
    Subscribe: "Subscribed",
    xpert: "Xpert Premium",
    Amountpaid: "Amount Paid",
    Renewal: "Renewal Date",
    Prefered: "Prefered Bank",
    Billed: "Billed Every",
    Auto: "Auto Charge",
    Key: "Key Benefits",
  },
  block: {
    head: "Explore Funds",
    paragraph: "",
  },
  SchemeDeatilsResponsive: {
    minsip: "Min SIP Amount",
    fund: "Fund Started",
    expense: "Expense Ratio",
    fundmanager: "Fund Manager",
    fundsize: "Fund Size (Rs. in CR)",
    CAGR: "CAGR (3 yrs)",
  },
  sideTabLabel: {
    investedHeader: "Invest in this fund on",
  },
  maturityFilter: {
    filter: "Filter by",
    status: "Status",
    issuerName: "Issuer Name",
    startDate: "Start Date",
    maturityDate: "Maturity Date",
    all: "All",
  },
  transactionFilter: {
    filter: "Filter by",
    schemes: "Schemes",
    transactionType: "Transaction Type",
    assetClass: "Asset Class",
    dateRange: "Date Range",
    search: "Search....",
    all: "All",
    filterBy: "Filter By",
    allScrips: "All Scrips",
    allSchemes: "Search Assets",
    transactSelect: "Select",
    transactionScheme: "Search Schemes",
    searchMutualFundCompanies: "Search Assets",

  },
  xRayFilter: {
    filter: "Filter by",
    category: "Category",
    schemes: "Schemes",
    transactionType: "Transaction Type",
    search: "Search....",
    all: "All",
  },
  button: {
    apply: "Apply",
    clear: "Clear",
    cancel: "Cancel",
    trackNow: "Track Now",
    createNow: "Create Now",
    no: "No",
    yes: "Yes",
    placeOrder: "Place Order",
    confirmOrder: "Confirm Order",
    cancel: "Cancel",
    start: "Start",
    getStarted: "Get Started",
    addTaxPayingCountry: "Add Tax Paying Country",
    submit: "Submit",
    addbank: "Add Bank",
    addMandate: "Add Mandate",
    startTracking: "Start Tracking",
    viewAnalysis: "View Analysis",
    faq: "FAQs"
  },
  xRayHeader: {
    scheme: "Scheme",
    category: "Asset Class",
    sortBy: "Sort by",
  },
  sortBy: {
    A_Z: "A to Z",
    Z_A: "Z to A",
    AUM: "AUM",
    returns: "Returns",
    marketValue: "Current Value",
    sipamount: "SIP Amount",
    rating: "Rating",
  },

  categoryallocation: {
    equity: "Equity",
    debt: "Debt",
    hybrid: "Hybrid",
  },
  profitlossfilter: {
    Filterby: "Filter by",
    Schemes: "Schemes",
    TransactionType: "Transaction Type",
    AssetClass: "Asset Class",
    DateRange: "Date Range",
  },
  ManuHeading: {
    portfolioName: "PORTFOLIO",
    Reports: "REPORT ",
    BuyAndSell: "BUY / SELL",
    MutualFundss: "MUTUAL FUNDS",
    Treading: "Trading",
    dashboard: "DASHBOARD",
    tarck: "TRACK",
    transact: "TRANSACT",
    FamilyReport: "Family Report"
  },
  Tableheader: {
    Nav: "Nav",
    price: "Price",
    SchemeName: "Scheme Name",
    ScripName: "Scrip Name",
    AssetName: "Asset Name",
    BondName: "Bond Name",
    NPSName: "NPS Name",
    InsuranceName: "Insurance Name",
    AifName: "Scheme Name",
    GoldName: "Instrument Name",
    dividend: "Dividend",
    Units: "Units",
    Quantity: "QTY",
    quantity: "QTY",
    size: "Size",
    InvestedValue: "Invested",
    amount: "Amount (₹)",
    CurrentValue: "Current Value (₹)",
    Realised: "DIV.PAYOUT(₹)",
    unrealisedint: "Unrealised int(R)",
    unrealised: "Unrealised G/L (₹)",
    unrealisedGL: "G / L(%)",
    redemption: "Redemption",
    AsOn: "As On ",
    totaltext: "% of total",
    Sip: "SIP",
    Net: "Net G/L(₹)",
    ROI: "ROI (%)",
    SubheadingNet: "XIRR (%)",
    Subheadingcurrent: "ALLOCATION (%)",
    Subheadingunits: "AVG.NAV(₹)",
    Subheadingprice: "AVG.PRICE(₹)",
    Avgprice: "AVG.Price",
    CurrentGain: "Current Gain (₹)",
    MarketValue: "Market Value",
  },
  runningSip: {
    scheme: "SCHEME NAME",
    scripname: "SCRIP NAME",
    quantity: "QUANTITY",
    price: "PRICE",
    prices: "price",
    purtotalprice: "Avg.Price",
    purprices: "Avg.PRICE",
    totalprice: "Total Price",
    simamts: "SIP Amount",
    simamt: "SIP Amount /",
    folio: "Folio no",
    sipFrequency: " Frequency	",
    startdate: "Start Date",
    endDate: "End Date",
    totalUnits: "Total Units",
    purprice: "Avg.NAV",
    puramount: "Pur.Amount",
    amount: "Div.Amount",
    nav: "Nav",
    curAmount: "CURR.AMOUNT",
    profit: "Gain/loss",
    xirr: "XIRR",
    total: "Grand Total",
    cancelpopup: "Are you sure want to cancel this SIP?",
    cancelSTPpopup: "Are you sure want to cancel this STP?",
    cancelOrderpopup: "Are you sure want to cancel this Order?",

  },
  logoutmenu: {
    welcome: "Welcome",
    username: "Sabri Raja Muthuraman",
    profile: "My Profile",
    account: " Switch Account",
    feedback: "Send Feedback",
    logout: "Logout",
  },
  Admin: {
    errormessage: " Client ID Not Available",
  },
  schemedetails: {
    company: "COMPANY",
    sector: "SECTOR",
    instrument: "INSTRUMENT",
    assets: "ASSETS",
    Title: "Top 10 Holdings",
  },
  sipPopup: {
    heading: "Start your SIP",
    title: "Your Monthly Instalment Amount",
    continue: "Continue",
    cart: "Add to cart",
    sipValidation: "SIP start date is mandatory",
    minimumValue: "Amount should be minimum ",
    alreadyInCart: "Already in the cart",
    addTocart: "Added to cart Successfully",
  },
  investmentPopup: {
    heading: "One Time Investment",
    title: "Your Investment Amount",
    continue: "Continue",
    cart: "Add to cart",
    minimumAmount: "Purchase Amount should be minimum ",
    maximumAmount: "Purchase Amount can be maximimum ",
    tooLargeAmount: "Purchase Amount is larger than allowed",
    amountValidation: "Amount is mandatory",
    amountMultiplerValidation: "Purchase Amount should be multiple of ",
    minimum7days: "Start date should have minimum 7 days gap from today",
    keySIP: "keySIP",
    keyOneTime: "keyOneTime",
    keyRedemption: "keyRedemption",
    keySwitch: "keySwitch",
    keySTP: "keySTP",
    keySWP: "keySWT",
    yFlag: "Y",
    successMsg: "success",
    purchaseNotAllowed: "Purchase Not Allowed for this Scheme",
    redeemNotAllowed: "Redemption Not Allowed for this Scheme",
    swpNotAllowed: "SWP Not Allowed for this Scheme",
    stpNotAllowed: "STP Not Allowed for this Scheme",
    switchNotAllowed: "Switch Not Allowed for this Scheme",
    sipNotAllowed: "SIP Not Allowed for this Scheme",
  },
  schemeFilterLabels: {
    amc: "AMC",
    availableToInvest: "Available to Invest",
    rating: "Rating",
    search: "Search",
    category: "Category",
    riskProfile: "Risk Type",
    searchResult: "Search Results",
    searchResults: "Search Result",
    reset: "Reset",
    trendingTop10MutualFund: "Trending Top 10 Mutual Funds",
    sortBy: "Sort by",
  },
  bigPopup: {
    sipDetails: "SIP Details",
    change: "Change",
    category: "Category",
    risk: "Risk",
    AUM: "AUM",
    investmentDetails: "Investment Details",
    amount: "Amount",
    sipFrequency: "SIP Frequency",
    sipDate: "SIP Date",
    noOfInstallments: "No of Instalments",
    checkBox: "First Payment is today",
    addToCart: "Add to Cart",
    saveDetails: "Save Details",
    editSip: "Edit SIP",
    editOnetime: "Edit One Time",
    monthly: "Monthly",
    quarterly: "Quarterly",
    yearly: "Yearly",
    sip: "SIP",
    onetime: "One Time",
    minimumInstallment: "Minimum 12 Instalments",
    nav: "NAV as on",
    amountValidation: "Amount is mandatory",
    installmentsValidation: "No of instalments is mandatory",
    firstInstallmentsDate: "Your next instalment is ",
    firstInstallmentWillBe: "Your instalment will be ",
    minimum7days: "Start date should have minimum 7 days gap from today",
    dateMismatch: "SIP date is not allowed, please update the cart and place the order",
    cartDateMismatch: "SIP date is not allowed, please update the cart",

    minimum30days: "Minimum 30 days",
  },
  paymentSummary: {
    rupeeSymbol: "₹",
    Main: "Summary",
    Scheme: "Scheme",
    Header1: "Investor Details",
    Header2: "Scheme Details",
    Folio: 'Folio',
    OrderType: "Order Type",
    SIPAmount: 'SIP Amount',
    OneTimeAmount: 'One Time Amount',
    SIPFrequency: "SIP Frequency",
    SIPStartDate: "Next SIP Date",
    NOOfInstalments: "No. of Instalments",
    TotalSIPAmount: "Total SIP Amount for Month",
    FirstPayment: "First Payment by today",
    TotalOneTimeAmountForMonth: 'Total One Time Amount for Month',
    Schemes: "Schemes"
  },
  deletePopup: {
    confirmation: "Confirmation",
    yes: "Yes",
    no: "No",
    delete1: "Do you want to delete the",
    delete2: "scheme from cart ?",
  },

  cart: {
    emptyCart: "Your Cart is empty",

    moreButton: "Browse Some Schemes",
    placesip: "Place All SIP Orders in Cart ",
    placesipall: "Place Orders for Selected Cards",
    placeonetime: "Place All One Time Orders in Cart ",
    selectedcart: "Place Orders for Selected Cards ",
    FolioNo: "Folio No : ",
    Ucc: "UCC : ",
    SIPFrequency: "SIP Frequency  ",
    SIPDate: "Next SIP Date ",
    SIPStartDate: "SIP Start Date ",
    NOofInstallments: "No.of Instalments ",
    SIP: "SIP",
    OrderType: "Order Type",
    SIPAmount: "SIP Amount",
    ConfirmOrder: " Confirm Order",
    Amount: "Amount",
    OneTime: "One Time",
    Edit: "Edit",
    Delete: "Delete",
    carttoselect: "  Click on the card to select",
    removedcart: " removed from your cart",
    Frequency: "SIP Frequency  ",
    minimumPurchaseAmount: "Purchase Amount should be minimum ₹5,000",
    backToPortfolioPage: "Back To Portfolio Page"
  },
  tranactionSchemesDetails: {
    Performance: "Performance",
    SIPHeader: "Your Monthly Instalment Amount",
    switchSIP: "SIP",
    switchOneTime: "One Time",
    switchAsset: "Asset",
    switchSector: "Sector",
    SIPTextHeader: "Enter Instalment Amount",
    OneTimeTextHeader: "Your Investment Amount",
    SIPButton: "Start your SIP",
    OneTimeButton: "Submit Order",
    addToCart: "Add to Cart",
    Returns: "Returns",
    oneWeek: "1W",
    threemonth: "3M",
    sixmonth: "6M",
    OneYear: "1Y",
    ThreeYear: "3Y",
    FiveYear: "5Y",
    Max: "Max",
    SinceInception: "SINCE INCEPTION",
    SimilarView: "View Similar Schemes",
    SimilarHide: "Hide Similar Schemes",
    Company: "Company",
    Sector: "Sector",
    INSTRUMENT: "INSTRUMENT",
    ASSETS: "%ASSETS",
    AddToCartMessageSuccessMessage: "added to your cart",
    SIPandOneTimeErrorMessage: "Investment amount should not be blank",
    Somethingwentwrong: "Something went wrong please try again",
    Nav: "Nav as on",
    SchemeName: "Scheme Name",
  },
  transactionSummary: {
    date: "DATE",
    schemeName: "SCHEME NAME",
    scripName: "SCRIP NAME",
    assetClass: "ASSET CLASS",
    transactionType: "TXN TYPE",
    TransactionNature: "TXN NATURE",
    folioNumber: "FOLIO NUMBER",
    contractno: "CONTRACT NUMBER",
    units: "UNITS",
    quantity: "QTY",
    nav: "NAV",
    price: "PRICE",
    amount: "AMOUNT",
  },
  mandate: {
    header: "Create your Mandate",
    amount: "Amount",
    createMandate: "Create Mandate",
    registrationSuccessMessage: "MANDATE REGISTRATION DONE SUCCESSFULLY",
    bankDropdownPlaceholder: "Select Your Bank",
    mandateDropdownPlaceholder: "Select Mandate Type",
  },

  folioPopup: {
    selectFolio: " Select UCC",
    schemes: "Schemes",
    newFolio: "Create New Folio",
    folio: "Folio",
    ucc: "UCC",
    addtocart: "Add to Cart",
    continue: "Continue",
  },
  transactionOrder: {
    schemenames: "Scheme Name",
    dates: "Date",
    amounts: "Amount",
    type: "Type",
    statuses: "Status",
    paymentStatus: "Payment Status"
  },
  upComingSIP: {
    schemenames: "SCHEME NAME",
    dates: "DATE",
    amounts: "AMOUNT",
    type: "TYPE",
    AMCName: "AMC",
    clientName: "NAME",
  },

  STP: {
    STP: "STP (Systematic Transfer Plan)",
    stpDetails: "STP Details",
    stpof: "Schemes to STP of",
    scheme: "Scheme to STP to",
    stpType: "STP Type",
    amount: "Amount",
    minimumAmount: "Minimum Amount ₹1,000",
    stpstart: "STP Start Date",
    firstOrder: "First Order is Today",
    UCC: "UCC",

    instalment: "STP Instalments",
    minimumInstallment: "Minimum Instalments 6",
    Folio: "Folio",
    STPStartDate: "Your STP Start Date is",
    ReviewSTPDetails: "Review STP Details",
    STPoutFrom: "STP-out from",
    STPinto: "STP-in to",
    STPType: "STP Type",
    Amount: "Amount",
    STPInstalments: "STP Instalments",
    StartDate: "STP Start Date",
  },

  switch: {
    Switch: "Switch",
    SwitchDetails: "Switch Details",
    ReviewSwitchDetails: "Review Switch Details",
    Folio: "Folio",
    SchemetoSwitch: "Scheme to Switch-in to",
    SchemesSwitchout: "Schemes to Switch-out of",
    Type: "Type",
    Amount: "Amount",
    Units: "Units",
    AvailableAmount: "Available Units",
    ExitLoad: "Exit Load",
    ShortTermUnit: "ShortTerm Unit",
    LongTermUnit: "LongTerm Unit",
    Switchoutfrom: "Switch-out from",
    Switchinto: "Switch-in to",
    OrderType: "Order Type",
  },

  SWP: {
    SWP: " SWP (Systematic Withdrawal Plan)",
    SWPDetails: "SWP Details",
    SchemestoSWPof: "Schemes to SWP of",
    SWPtype: "SWP Type",
    Amount: "Amount",
    SWPStartDate: "SWP Start Date",
    Folio: "Folio",
    YourSWPStartDate: "Your SWP Start Date is",
    SWPInstalments: "SWP Instalments",
    PlaceOrder: "Place Order",
    ReviewSWPDetails: "Review SWP Details",
    RedeemFrom: "Redeem From",
    FirstOrderToday: "First Order is Today",
    BranchName: "Branch Name",
    BankAccountType: "Account Type",
    AccountNumber: "Account Number",
    IFSCCode: "IFSC Code",
  },

  marketCapAllocation: {
    largeCap: "Large Cap",
    midCap: "Mid Cap",
    smallCap: "Small Cap",
    others: "Others",
    amountAge: "AMOUNT / ",
    Age: "(%) AGE",
  },
  assetAllocation: {
    equity: "Equity",
    debt: "Debt",
    gold: "Gold",
    others: "Others",
    amountAge: "AMOUNT",
    totalamount: "TOTAL AMOUNT",
    Age: "(%) AGE",
  },
  portfolioinsight: {
    schemeName: "SCHEMENAME",
    purprice: "PUR.AMOUNT (₹)",
    curramt: "CURR.AMT(₹)",
    profitloss: "PROFIT/ LOSS (₹)",
    Netgain: "NET GAIN(₹)",
    xirr: "XIRR(% age)",
    holding: "HOLDING (%age)",
    SIPAmount: "SIP AMOUNT(₹)",
  },
  Redemption: {
    redempationHeader: "Redeem Details",
    redemptionType: "Redeem Type",
    schemeTo: "Scheme to Redeem From",
    redemptionName: "Redeem",
    RedeemFrom: "Redeem From",
    OrderType: "Order Type",
    ReviewRedemptionDetails: "Review Redeem Details",
    RedemptionType: "Redeem Type",
    Amount: "Amount",
    Units: "Units",
    AvailableAmount: "Available Amount",
    ExitLoad: "Exit Load",
    ShortTermUnit: "Short Term Unit",
    LongTermUnit: "LongTerm Unit",
    BranchName: "Branch Name",
    BankAccountType: "  Account Type",
    AccountNumber: "Account Number",
    IFSCCode: "IFSC Code",
    Approximateexits: "Approximate exits load for this transaction: *",
    editLoad:
      "*Edit-load-free units & charge shown above are indicative. Actual units that would be subject  to exit load & the resultant exit load charge may vary significantly. The indicative exit load shown above is based on last available NAV of selected scheme. However the exit load that to be levied would be based on the actual NAV application for the withdrawal.",
    nextExitLoad:
      " # The next exit load-free units are estimates. Actual load-free units may vary significantly.",
    placeOrder: "Place Order",
    Folio: "Folio",
    AvailableNoofUnits: "Available No. of Units",
    ExitLoadFreeUnits: "Exit Load Free Units",
    ShortTermGainUnits: "Short Term Gain Units",
    LongTermGainUnits: "Long Term Gain Units",
    ProceedBankDetails: "Proceed Bank Details",
  },
  // Added by Muthu Lakshmi K
  riskProfile: {
    Warning: "Profile is incomplete. Complete now",
    riskProfile: "Risk Profile",
    riskContent:
      "Risk profile indiciates the level of risk you should follow while making the investments",
    riskTest: "Take Your Risk Profile Test",
    riskReport: "My Risk Profile Report",
    getStart: "Get Started",
    start: "Start",
    view: "View",
    getMail: "Get it on Email",
    help: "Get help",
    skip: "SKIP",
    retest: "Retest",
  },
  UploadMandate: {
    upload: "Upload Mandate",
    SIP: "To ensure that next SIP gets auto-deducted",
    steps: "STEPS",
    firstStep:
      "1. Download the Pre-Filled Mandate From sent on Email or click here to download.",
    secStep: "2. Take a Print Out of the from.",
    thirdStep: "3. Duly Sign the form as per Bank Records.",
    forthStep: "4. Scan/Click a clear image of the form.",
    fifthStep: "5. Upload PDF or JPEG file by clicking the button below",
  },
  Dashboard: {
    alertmessage: "Please complete your KYC",
    licenceExpire: "Your License ",
    createnow: "Continue",
    more: "...more",
    less: "less",
    currentinvestment: "Current Investment",
    starttracking: "Start Tracking",
    startinvest: "Start Investing",
    subscribe: "Subscribe",
    starter: "Starter DIY",
    free: "(free)",
    ourOfferings: "Our Offerings",
    setYourGoals: "Set Your Goals",
    lifegoals: "Life Goals",
    familysecurity: "Family Security",
    myfinancialplan: "My Financial Plan",
    planforretirement: "Plan for your retirement",
    planonecrore: "Plan for first 1 crore",
    planforhome: "Plan for buying a house",
    startLearning: "Start Learning",
    register: "Register",
    upcommingwebminar: "Upcoming Webinar",
    wordoftheday: "Word the Day",
    exploreblogs: "Explore Blogs and Articles",
  },
  Path: {
    NotFound: "/notfound",
    PageNotFound: "/404",
    Basket: "/user/basket",
    BasketDetails: "/user/basketdetails",
    Portfolio: "/user/portfolio",
    Networkerror: "/user/networkerror",
    Reports: "/user/report",
    MutualTab: "/user/mutualfunds",
    Scheme: "/user/schemes",
    TradingDetail: "/user/tradingdetails",
    Cart: "/user/cart/:selected",
    NPSTab: "/user/nps",
    InsuranceTab: "/user/insurance",
    MLDTab: "/user/mld",
    LiquiloansTab: "/user/liquiloans",
    Equity: "/user/stocks",
    FDTab: "/user/fixeddeposit",
    RealEstateTab: "/user/realestate",
    GoldTab: "/user/goldandsilver",
    AIFTab: "/user/aif",
    BondsTab: "/user/bonds",
    EPFTab: "/user/epf",


    GoldBondsTab: "/user/goldandsilver",
    MFTransaction: "/user/mfTransaction",
    Login: "/",
    AifDataEntry: "/aifdataentry",
    Api: "/apicall",
    WelcomePage: "/congratulations",
    Signup: "/signup",
    DeleteAccount: "/delete-my-account",
    Trading: "/user/trading",
    Payment: "/payment/:buy",
    Aif: "/aif",
    Pms: "/pms",
    RealEstate: "/realestatedataentry",
    RealEstateListing: "/realestate",
    Alert: "/alerts",
    FamilyReport: "/familyreport",

    Bullionlisting: '/goldandsilver',
    Bullion: '/goldandsilverdataentry',
    Profile: "/profile",
    UserDetails: "/profile/userdetails",
    MyFamily: "/profile/myfamily",
    RiskPlan: "/profile/riskplan",
    Account: "/profile/myaccount",
    MyPlan: "/profile/myplan",

    MyFinancialPlan: "/profile/myfinancialplan",
    RiaPlanComplete: "/profile/subscriptioncomplete",
    PurchasedPlan: "/profile/planpurchased",
    Trade: "/trade",
    Stocks: "/Stocks",
    Nps: "/nps",
    NpsDataEntry: "/npsdataentry",
    InsuranceDataEntry: "/insurancedataentry",
    Insurance: "/insurance",
    TradedBonds: "/tradableBonds",
    Product: "/user/product",
    SidemenuUser: "/user",
    admin: "/admin/:id",
    cartOneTime: "/user/cart/onetime",
    cartSip: "/user/cart/sip",
    TransactionOrder: "/user/transactionorder",
    Redemption: "/redemption",
    Switch: "/switch",
    SWP: "/SWP",
    STP: "/STP",
    Risk: "/riskProfile",
    Dashboard: "/Dashboard",
    UploadMandate: "/UploadMandate",
    PaymentBasket: "/PaymentBasket",
    EPF: "/epf",
    StepperAdvisory: "/RIAPlans",
    Ekyc: "/Ekyc/:id",
    Tracking: "/Tracking",
    Transact: "/user/Transact",
    UploadProcessing: "/UploadProcessing/",
    PaymentBasket: "/paymentbasket",
    EquityTracking: "/Mftracking",
    FdDebentures: "/trade/FixedDeposit",
    Debentures: "/Debentures",
    Bonds: "/Bonds",
  },
  fdForm: {
    Type: "Transaction Type",
    fixed: "Deposit Name",
    fdrnumber: "FDR Number",
    fdrNewValue: 'FDR Number',
    fdrOldValue: 'Old FDR Number',
    fdrNewTextValue: 'New FDR Number',
    selectTransactionType: "Select Transaction Type",
    selectOldFDRNo: "Select FDR Old No",
    enterFDRNumber: "Enter FDR Number",
    selectInterestType: "Select Interest Type",
    selectPaymentType: "Select Interest Payment",
    selectFDRNo: "Select FDR No",
    enterNarration: "Enter Narration",
    save: "Save",
    select: "Select",
    add: "Add",
    cancel: "Cancel",
    Category: 'Category',
    Issurename: 'Issuer Name',
    fd: "Deposits",
    Bonds: "Physical Bonds",
    popupAsset: "New Deposit",
    Transaction: "Transaction",
    investment: "Interest Rate",
    Interest: "Interest",
    tds: "TDS",
    netAmount: "Net Amount",
    value: "0.00",
    investmentType: "Interest Type",
    InvvestPayment: "Interest Payment",
    Maturity: "Maturity Date",
    Lockin: "Lock-in Period",
    date: "Date",
    Amount: "Amount",
    principalAmount: "From Principal Amt",
    Quantity: "Quantity",
    Rate: "Rate",
    Narration: "Narration",
    Debentures: " Debentures",
  },
  productList: {
    MutualFunds: "Mutual Funds",
    Epf: "EPF",
    Pms: "AIF / PMS",
    Equity: "Stocks / ETF ",
    Fixed: "Fixed Income  ",
    Insurance: "Insurance",
    Gold: "Gold / Silver",
    RealEstate: "Real Estate",
    healthHeader: "Add HEALTH Insurance information",
    generalHeader: "Add GENERAL Insurance information",
    lifeHeader: "Add LIFE Insurance information",
    healthHeaderUpdate: "Update HEALTH Insurance information",
    generalHeaderUpdate: "Update GENERAL Insurance information",
    lifeHeaderUpdate: "Update LIFE Insurance information",
    NPS: "NPS",
    TradableBonds: "Bonds",
    NCD: "NCD",
    physicalBonds: "Physical Bonds",
    FD: "Deposits",

    Debentures: "Debentures",
    tradedbond: "Bonds",
    Bullion: "Gold / Silver",
    healthInsurance: "General / Life / Health Insurance"
  },
  Transact: {
    searchPlaceholder: "Search for any products and start investing",
    faqs: "FAQs",
    finvat: `${Asset.companyName}: Our transaction platform`,
    subContent: "It brings you the lowest cost possible transaction options.",
    nextSubContent: `Save money while transaction with ${Asset.companyName}`,
    endContent: "Why Invest with us?",
    cdslContent: "CDSL full form is Central Depository Services Limited and is the second Indian securities depository institution.  CDSL holds various securities like shares, bonds, etc., in an electronic format.",
    nsdlContent: " NSDL works to support the investors and brokers in the capital market of the country. NSDL aims at ensuring the safety and soundness of Indian marketplaces by developing settlement solutions that increase efficiency, minimize risk and reduce costs.",
    camsContent: "CAMS stands for Computer Age Management Services – a SEBI-registered Registrar & Transfer (R&T) Agency. It provides technology-enabled processing solutions to investors and mutual fund companies alike.CAMS collaborates with fund houses to build a user-friendly service delivery model to investors.",
    bondsubContent: "Bonds – also known as fixed income instruments – are used by governments or companies to raise money by borrowing from investors. Bonds are typically issued to raise funds for specific projects. In return, the bond issuer promises to pay back the investment, with interest, over a certain period of time",
    fdsubContent:
      "A fixed deposit is a low-risk financial instrument that helps investors grow savings at a fixed rate of interest that is higher than the interest rates offered by savings accounts. The convenience of investing, along with the safety of your deposit, can help you plan your short-term and long-term financial goals with ease",
    debenturessubContent:
      "Non-convertible debentures fall under the debt category. They cannot be converted into equity or stocks. NCDs have a fixed maturity date and the interest can be paid along with the principal amount either monthly, quarterly, or annually depending on the fixed tenure specified. They benefit investors with their supreme returns, liquidity, low risk and tax benefits when compared to that of convertible debentures",
    stockssubContent:
      "Stocks are bought and sold predominantly on stock exchanges, though there can be private sales as well, and are the foundation of many individual investors' portfolios. These transactions have to conform to government regulations which are meant to protect investors from fraudulent practices. Historically, they have outperformed most other investments over the long run.﻿ These investments can be purchased from most online stock brokers",
    tradebondsContent:
      "A bond is a financial instrument that works by allowing individuals to loan cash to institutions such as governments or companies. The institution will pay a defined interest rate on the investment for the duration of the bond, and then give the original sum back at the end of the loan’s term",
    aifContent: "Alternative Investment Funds or AIF means any fund established or incorporated in India which is a privately pooled investment vehicle which collects funds from sophisticated investors, whether Indian or foreign, for investing it in accordance with a defined investment policy for the benefit of its investors.",
    healthInsurance: "We leave no stone unturned to ensure a comfortable life for our loved ones. Health is one aspect of our lives that has become a priority today. The world is facing an extraordinary situation amidst the pandemic. Thus, it calls for extraordinary efforts to safeguard the health of your family. A family health insurance policy offers you a financial safety net against unexpected medical expenditures",
    realEstate: "Real estate is the land along with any permanent improvements attached to the land, whether natural or man-made-including water, trees, minerals, buildings, homes, fences, and bridges. Real estate is a form of real property.",
    bullion: 'A bullion market is a market through which buyers and sellers trade gold and silver as well as associated derivatives. There are various gold /silver markets dotted around the world, although the London Gold / Silver Market is known as the primary global market trading platform for gold and silver.'
    , npsContent: "National Pension Scheme, popularly known as NPS, is a government-owned social security investment option, designed for the employees of private, public or unorganised sectors. Through these schemes, the investors can invest a portion of their income at a regular interval."
  },

  Insurance: {
    insuredFor: 'Insured For',
    policyName: "Policy Name",
    policyNamePlaceHolder: 'Select Policy Name',
    polcyNo: "Policy No.",
    policyNoPlaceHolder: "Enter Policy No",
    policyType: "Policy Type",
    policyFor: "Policy For",
    policyTypePlaceHolder: "Select Policy Type",
    policyForPlaceHolder: "Select Policy For",
    premiumFrequencyPlaceHolder: "Select Premium Frequency",
    premiumFrequency: "Premium Frequency",
    genderPlaceHolder: "Select Gender",
    gender: "Gender",
    transaction: "Transactions",
    startDate: "Start Date",
    startDatePlaceHolder: "Select Start Date",
    dateOfBirthPlaceHolder: "Select Date of Birth",
    asOnDatePlaceHolder: "Select As On Date",
    coverAmount: "Cover Amount",
    premiumAmount: "Premium Amount",
    policyTerm: "Policy Term",
    premiumPayingTerm: "Premium Paying Term",
    enterPremiumPayingTerm: "Enter Premium Paying Term",
    enterYears: "Enter Years",
    zero: "0",
    bankDetails: "BANK DETAILS",
    bankAccount: "Bank Account",
    bankNamePlaceHolder: "Select Bank Account",
    riderAddOns: "Rider / Add-ons",
    riderNamePlaceHolder: "Select Rider Name",
    riderName: "Rider Name",
    riderSumAssured: "Rider Sum Assured",
    riderFor: "Rider For",
    riderForPlaceHolder: "Select Rider For",
    nomineeDetails: "Nominee Details",
    nomineeName: "Nominee Name",
    nomineeNamePlaceHolder: "Enter Nominee Name",
    nomineeRelationship: "Nominee Relationship",
    nomineeRelationshipPlaceHolder: "Select Relationship",
    dateOfBirth: "Date of Birth",
    guardian: "Guardian / Appointee (if minor)",
    guardianPlaceHolder: "Enter Guardian Name",
    nomineePercentage: "Nominee Percentage",
    bankName: "Bank Name",
    bankNamePlaceHolderPopup: "Select Bank Name",
    accountNumber: "Account Number",
    accountNumberPlaceHolder: "Enter Account Number",
    ifscCode: "IFSC Code",
    ifscCodePlaceHolder: "Enter IFSC Code",
    riderDataList: [{ label: 'Rider Name', }, { label: 'Rider For' }, { label: 'Rider Sum Assured' },],
    riderDataListLife: [{ label: 'Rider Name', }, { label: 'Rider Term (Years)' }, { label: 'Rider Sum Assured' },],
    riderDataListGeneral: [{ label: 'Rider Name', }, { label: 'Rider Sum Assured' },],
    nickName: "Nick Name",
    nickNamePlaceHolder: "Enter Nick Name",
    namePlaceHolder: "Enter Name",
    name: "Name",
    selectOneInsure: "Kindly select one insure",
    required: "Required",
    minimum1000: "Minimum Amount 1000",
    minimum10000: "Minimum Amount 10000",
    allFieldsMandatory: "All fields are mandatory",
    confirmationText: "Are you sure want to delete this nominee?",
    confirmationTextBeneficiary: "Are you sure want to delete this beneficiary?",
    confirmationTextTrustee: "Are you sure want to delete this trustee?",
    confirmationTextULIP: "Are you sure want to delete this ULIP?",
    confirmationInsuranceText: "Are you sure want to delete this insurance?",
    mWPA: "Is MWPA attached to the policy",
    uLIPDetails: "ULIP Details",
    asOnDate: "As on Date",
    schemeName: "Scheme Name",
    schemeNamePlaceHolder: "Select Scheme Name",
    units: "Units",
    unitsPlaceHolder: "Enter Units",
    latestNAV: "Latest NAV",
    latestNAVPlaceHolder: "Enter Latest NAV",
    latestValue: "Latest Value",
    latestValuePlaceHolder: "Enter Latest Value",
    trusteeDetails: "Trustee Details",
    beneficiaryDetails: "Beneficiary Details",
    trusteeName: "Trustee Name",
    trusteeNamePlaceHolder: "Enter Trustee Name",
    trusteeRelationship: "Trustee Relationship",
    trusteeRelationshipPlaceHolder: "Select Trustee Relationship",
    beneficiaryName: "Beneficiary Name",
    beneficiaryNamePlaceHolder: "Enter Beneficiary Name",
    beneficiaryRelationship: "Beneficiary Relationship",
    beneficiaryRelationshipPlaceHolder: "Select Beneficiary Relationship",
    beneficiaryPercentage: "Beneficiary Percentage",
    schemeNameExists: "Scheme Name already exists",
  },
  InsuranceReport: {
    lifeInsurance: 'Life Insurance',
    healthInsurance: "Health Insurance",
    generalInsurance: "General Insurance",
    coverAmount: "Cover Amount",
    preminum: "Premium",
    frequency: "Frequency",
    renewalDate: "Renewal Date",
    currentValue: 'Current Value',
    type: "Type",
    policyNo: "Policy No.",
    issueDate: "Issue Date",
    term: "Term",
    PPT: "PPT",
    expirationDate: "Expiration Date",
    typeOfLifeInsurance: "Type of Life Insurance",
    typeOfHealthInsurance: 'Type of Health Insurance',
  },
  MutualFundsTracking: {
    header: "Setup Mutual Funds Tracking",
    SubHeader: ` keep track on Non-${Asset.companyName} executed Funds with ease`,
    email: "Email: ",
    EmailName: " Pranabt@ gmail.com",
    stepOne: "Step 1: ",
    stepTwo: "Step 2: ",
    stepThree: "Step 3: ",
    stepFour: "Step 4: ",
    stepFive: "Step 5: ",
    stepSix: "Step 6: ",
    stepSevan: "Step 7: ",
    ruleOne: "go to ",
    ruleOneBold: "CAMS Website",
    ruleTwo: 'Click on "Detailed" in "Statement Type"',
    ruleThree: 'Select "Specific Period"',
    ruleFour: 'Select "01-01-1985" in "From Date"',
    ruleFive: 'Select "With Zero balace follos"',
    ruleSix: "Enter our email address.",
    ruleSevan: "Enter <PAN CARD> as your password & ",
    ruleSevanBold: "Submit",
    doumloadButton: "Download the CAS &",
  },
  errorHandle: {
    errorCode: "505",
    pageNotFoundErrorCode: "404",
    whoops: "Whoops!",
    errorConfessionMsg: "Something went wrong on our end,",
    couldNotFoundPage: " We couldn't find this page.",
    errorConfessionMsg2: " we've alerted our engineers to see what happened.",
    letsGoDashboard: "Let's Go Dashboard"
  },
  EquityTackingLabels: {
    equityHeader: "Setup ",
    mfheaders: " Tracking",
    password: "Enter Password",
    continue: "Continue",
    trackDashboard: "Track Dashboard",
    directShare: "Track Direct Shares PMS, AIF Bonds",
    // equityPortfolio:"Two Methods for Tracking existing Equity Portfolio",
    equityPortfolio: "Tracking Existing ",
    portfolio: " Portfolio",
    SelectOne: "Select any one",
    statement: "How to generate CAMS Statement? ",
    MutualfunduplaodButton: " CAMS Upload",
    EquityuplaodButton: " NSDL Upload",
    CDSLuplaodButton: "  CDSL Upload",
    Addmanually: 'Add Manually',
    upload: "Upload",
    Uploadstatement: "Statement",
    popupSubTitle: "Upload PDF received from",
    connectBroker: "Connect to Broker",
    PopupHeadingCAMS: "Upload CAMS Statement",
    PopupHeadingNSDL: "Upload NSDL Statement",
    popupSubTitleCAMS: "Upload PDF received from CAMS",
    popupSubTitleNSDL: "Upload PDF received from NSDL",
    equityPassword: "Password to open the Statement",
    successPopupHeading: " Import Successful",
    // successPopupmsg: "you have 10 stock(s) worth ₹30,342",
    successPopupmsg: "Your portfolio worth has  ",
    currentValue: "Current Value ",
    Important: "(Imported)",
    Finnovate: `(${Asset.companyName})`,
    DayReturn: "1 Day Return  ",
    PopupButton: "Submit",
    fileErrorMessage: "PDF only allowed ",
    pan: "PAN"
  },
  Basket: {
    HighReturn: "HIGH RETURN",
    High: "HIGH RETURNS",
    TaxSaving: "TAX SAVING",
    Better: "BETTER THAN FD",
    Company: "TOP COMPANIES",
    Sip: "SIP WITH ₹500",
    Sector: "SECTOR BETS",
  },
  //Added by Celsia on 29-Jun-2021 for New Development
  myFamilyDetails: {
    FullName: "Full Name",
    // DateofBirth: "Date of Birth",
    Gender: "Gender",
    MaritalStatus: "Marital Status",
    MyFamily: "My Family",
    Relationship: "Relationship",
    FullName: "Full Name",
    Gender: "Gender",
    DateofBirth: "Date of Birth",
    MartialStatus: "Martital Status",
  },
  //Ended
  ForgotPassword: {
    ForgotPassword: "Forgot Password",
    submit: "SUBMIT",
    verifyOtp: "Verify OTP",
    resend: "Resend OTP",
    digit: "Enter 6 digit code to verify",
    emailID: "Email ID",
    cancel: "CANCEL",
    changePassword: "Change Password",
    save: "SAVE",
  },
  payment: {
    selectMFAccount: "Select BSE MF Account",
    bseMfAccount: "BSE MF Account",
    selectMandate: "Select Mandate",
    bankAccount: "Bank Account",
    folioNumber: "Folio",
    selectBank: "Select Bank",
    selectFolio: "Select Folio",

    new: "New"
  },
  //denis add the Payment Success Page
  paymentSuccess: {
    Success: "Transaction Submitted Successfully",
    Order: "Order ID :",
  },
  paymentFaild: {
    Failed: "Transaction Submitted Failed",
  },
  checkBox: {
    markAsPrimary: " Mark as primary",
    firstOrderToday: "First Order is today",
    WithRedeemedUnits: "With Redeemed Units",
    WithRedeemedQTY: "With Sold Qty",
  },
  StructuredMenu: [{
    id: "1",
    tabTitle: "MLD",
    tabContent: "MLD",

  },
  {
    id: "2",
    tabTitle: "Liquiloans",
    tabContent: "Liquiloans",
    // enableMenu: [1, 2, 3, 4, 5, 6, 7, 8]
  },
    // {
    //   id: "3",
    //   tabTitle: "Transactions",
    //   tabContent: "Transactions",
    //   enableMenu: [1, 2, 3, 4, 5, 6, 7, 8]
    // },
  ],
  data: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
      enableMenu: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
      enableMenu: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
      enableMenu: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {
      id: "4",
      tabTitle: "Booked G/L",
      tabContent: "Profit&Loss",
      enableMenu: [1, 2, 3, 4, 5, 7, 8]
    },
    {
      id: "5",
      tabTitle: "Taxation Report",
      tabContent: "CapitalGain",
      enableMenu: [1, 2, 3]
    },
    {
      id: "6",
      tabTitle: "SIP Tracker",
      tabContent: "SIPTracker",
      enableMenu: [1]
    },
    // {
    //   id: "6",
    //   tabTitle: "Statement",
    //   tabContent: "AccountStatement",
    //   enableMenu: [2]
    // },

    {
      id: "7",
      tabTitle: "Account Statement",
      tabContent: "AccountStatement",
      enableMenu: [1, 3]
    },

    {
      id: "9",
      tabTitle: "Portfolio Details",
      tabContent: "Portfolioreport",
      enableMenu: [1, 2, 3]
    },
    {
      id: "10",
      tabTitle: "AFR Report",
      tabContent: "AFR",
      enableMenu: [1]
    },
    // {
    //   id: "8",
    //   tabTitle: "X-Ray With Sold Uits",
    //   tabContent: "XrayWithRedemption",
    //   enableMenu: [2, 3]
    // },
    {
      id: "11",
      tabTitle: "X-Ray With Redemption",
      tabContent: "XrayWithRedemption",
      enableMenu: [1]
    },
    {
      id: "12",
      tabTitle: "Asset Allocation",
      tabContent: "AssetAllocation",
      enableMenu: [1]
    },
    {
      id: "13",
      tabTitle: "Market Cap Allocation",
      tabContent: "MarketCapAllocation",
      enableMenu: [1, 2]
    },
    {
      id: "14",
      tabTitle: "Running SIP Summary",
      tabContent: "RunningSIPSummary",
      enableMenu: [1]
    },
  ],

  EpfTabData: [{
    id: "1",
    tabTitle: "Portfolio Details",
    tabContent: "PortfolioDetails",
  },
  {
    id: "2",
    tabTitle: "Transactions",
    tabContent: "Transactions",
  },],
  EquityTabdata: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
    },
    {
      id: "4",
      tabTitle: "Booked G/L",
      tabContent: "Profit&Loss",
    },
    {
      id: '5',
      tabTitle: "Taxation Report",
      tabContent: 'CapitalGain'
    },
    {
      id: "6",
      tabTitle: "Statement",
      tabContent: "AccountStatement",
    },

    {
      id: "7",
      tabTitle: "Portfolio Details",
      tabContent: "Portfolioreport",
    },
    {
      id: "8",
      tabTitle: "X-Ray With Sold Units",
      tabContent: "XrayWithRedemption",
    },
    {
      id: "9",
      tabTitle: "Market Cap Allocation",
      tabContent: "MarketCapAllocation",
    },
    {
      id: "10",
      tabTitle: "Transfer In",
      tabContent: "Transferin",
    },
  ],
  BondsTabdata: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
    },
    {
      id: "4",
      tabTitle: "Booked G/L",
      tabContent: "Profit&Loss",
    },
    {
      id: '5',
      tabTitle: "Taxation Report",
      tabContent: 'CapitalGain'
    },
    {
      id: "6",
      tabTitle: "Account Statement",
      tabContent: "AccountStatement",
    },

    {
      id: "7",
      tabTitle: "Portfolio Details",
      tabContent: "Portfolioreport",
    },
    {
      id: "8",
      tabTitle: "X-Ray With Sold Units",
      tabContent: "XrayWithRedemption",
    },
    {
      id: "9",
      tabTitle: "Transfer In",
      tabContent: "Transferin",
    },
    {
      id: "10",
      tabTitle: "Maturity Tracker",
      tabContent: "MaturityTracker",
    },
  ],
  AIFTabdata: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
    },
    // {
    //   id: "4",
    //   tabTitle: "Profit & Loss",
    //   tabContent: "Profit&Loss",
    // },

  ],
  RealEstateTabdata: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
    },
    {
      id: "4",
      tabTitle: "Booked G/L",
      tabContent: "Profit&Loss",
    },
  ],

  GoldBondsTabdata: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
    },
    {
      id: "4",
      tabTitle: "Booked G/L",
      tabContent: "Profit&Loss",
    },
    // {
    //   id: '5',
    //   tabTitle: "Taxation Report",
    //   tabContent: 'CapitalGain'
    // },
    // {
    //   id: "6",
    //   tabTitle: "Account Statement",
    //   tabContent: "AccountStatement",
    // },

    // {
    //   id: "7",
    //   tabTitle: "Portfolio Details",
    //   tabContent: "Portfolioreport",
    // },
    // {
    //   id: "8",
    //   tabTitle: "X-Ray With Sold Units",
    //   tabContent: "XrayWithRedemption",
    // },

  ],
  FixedDepositsTabdata: [
    {
      id: "1",
      tabTitle: "Summary",
      tabContent: "Summary",
    },
    {
      id: "2",
      tabTitle: "Portfolio X-Ray",
      tabContent: "PortfolioX-Ray",
    },
    {
      id: "3",
      tabTitle: "Transactions",
      tabContent: "Transactions",
    },
    {
      id: "4",
      tabTitle: "Booked G/L",
      tabContent: "Profit&Loss",
    },
    {
      id: "5",
      tabTitle: "Maturity Tracker",
      tabContent: "MaturityTracker",
    },
    // {
    //   id: '5',
    //   tabTitle: "Taxation Report",
    //   tabContent: 'CapitalGain'
    // },
    // {
    //   id: '6',
    //   tabTitle: "SIP Tracker",
    //   tabContent: 'SIPTracker'
    // },
    // {
    //   id: "6",
    //   tabTitle: "Account Statement",
    //   tabContent: "AccountStatement",
    // },

    // {
    //   id: "7",
    //   tabTitle: "Portfolio Details",
    //   tabContent: "Portfolioreport",
    // },
    // {
    //   id: "8",
    //   tabTitle: "X-Ray With Sold Units",
    //   tabContent: "XrayWithRedemption",
    // },

  ],

  TradingTabdata: [
    {
      id: "1",
      tabTitle: "MF Order Status",
      tabContent: "MFOrderStatus",
    },
    {
      id: "2",
      tabTitle: "Upcoming SIPs",
      tabContent: "UpcomingSIPs",
    },
    {
      id: "3",
      tabTitle: "Mandate",
      tabContent: "Mandate",
    },
    {
      id: "4",
      tabTitle: "Online SIPs",
      tabContent: "OnlineSips",
    },
    {
      id: "5",
      tabTitle: "Online STPs",
      tabContent: "OnlineStps",
    },
  ],
  InsuranceTabData: [
    {
      id: "1",
      tabTitle: "Health Insurance",
      tabContent: "HealthInsurance",
    },
    {
      id: "2",
      tabTitle: "General Insurance",
      tabContent: "GeneralInsurance",
    },
    {
      id: "3",
      tabTitle: "Life Insurance",
      tabContent: "LifeInsurance",
    },
  ],
  Date: [
    { label: "1st of every month", value: "01", id: 1 },
    { label: "2nd of every month", value: "02", id: 2 },
    { label: "3rd of every month", value: "03", id: 3 },
    { label: "4th of every month", value: "04", id: 4 },
    { label: "5th of every month", value: "05", id: 5 },
    { label: "6th of every month", value: "06", id: 6 },
    { label: "7th of every month", value: "07", id: 7 },
    { label: "8th of every month", value: "08", id: 8 },
    { label: "9th of every month", value: "09", id: 9 },
    { label: "10th of every month", value: "10", id: 10 },
    { label: "11th of every month", value: "11", id: 11 },
    { label: "12th of every month", value: "12", id: 12 },
    { label: "13th of every month", value: "13", id: 13 },
    { label: "14th of every month", value: "14", id: 14 },
    { label: "15th of every month", value: "15", id: 15 },
    { label: "16th of every month", value: "16", id: 16 },
    { label: "17th of every month", value: "17", id: 17 },
    { label: "18th of every month", value: "18", id: 18 },
    { label: "19th of every month", value: "19", id: 19 },
    { label: "20th of every month", value: "20", id: 20 },
    { label: "21st of every month", value: "21", id: 21 },
    { label: "22nd of every month", value: "22", id: 22 },
    { label: "23rd of every month", value: "23", id: 23 },
    { label: "24th of every month", value: "24", id: 24 },
    { label: "25th of every month", value: "25", id: 25 },
    { label: "26th of every month", value: "26", id: 26 },
    { label: "27th of every month", value: "27", id: 27 },
    { label: "28th of every month", value: "28", id: 28 },
  ],
};
export default labels;
