import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { TextField, MenuItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Color from "../../utils/colors/colors";
import styles from "../login/user-jss";
import {
  emailValidator,
  requireValidator,
  NameValidation,
  Encryption
} from "../../utils/commonfunction";
import Label from "../../utils/constants/labels";
import Grid from "@material-ui/core/Grid";
import CommonSnackbar from "../snackbar/snackbar";
import SignUp from "../../api/login/signup";
import secureStorage from "../../utils/securestorage";
import Textinput from "../textinput/textinput";
import labels from "../../utils/constants/labels";
import CommonLogin from "../commonLogin/common_login";
let a = [];
let timer = "";
let message = "";

class AccountDelete extends React.Component {
  // eslint-disable-line
  constructor() {
    super();
    this.myDiv = React.createRef();

    this.state = {
      showPassword: false,
      name: "",
      email: "",
      reason: "",
      feedback: "",
      password: "",
      nameError: "",
      passwordError: "",
      signupemail: "",
      pannumber: "",
      mobilenumber: "",
      newpassword: "",
      confirmpassword: "",
      deviceType: "",
      osType: "",
      timeStamp: "",
      timeZone: "",
      browserNameVersion: "",
      referrelUrl: "",
      signupemailerror: "",
      pannumbererror: "",
      mobilenumbererror: "",
      newpassworderror: "",
      confirmpassworderror: "",
      showsignup: false,
      showsetPassword: false,
      showconfirmPassword: false,
      open: false,
      signupmessage: "",
      errorMessage: "",
      page: "signup",
      loader: false,
      step: 1,
      emailotp: "",
      emailotperror: "",
      mobileotp: "",
      mobileotperror: "",
      message: "",
      newEmailAddress: "",
      newEmailAddressError: "",
      loaderemailotp: false,
      loaderphoneotp: false,
      loaderchangeemail: false,
      loadermobile: false,
      formisvalid: true,
      severity: "success",
      emailduplicate: false,
      panduplicate: false,
      mobileduplicate: false,
      changeemailflag: false,
      changemobilenumber: "",
      changemobilenumbererror: "",
      changemobileflag: "",
      loaderchangemobile: false,
    };
    // this.submit = this.submit.bind(this)
    this.submitSignup = this.submitSignup.bind(this);
  }
  componentDidMount() {
    this.myDiv.current.addEventListener("keydown", (e) => {
      //
      //   if (e.key === "Enter" && this.state.page === "login") {
      //     this.submit(e)
      //   }
      if (e.key === "Enter") {   //&& this.state.step === 1) {
        if (this.state.step === 1) {
          this.submitSignup(e);
        }
        else if (this.state.step === 2) {
          this.verifyemailotp(false);
        }
        else if (this.state.step === 3) {
          this.changeemailSubmit()
        }
        else if (this.state.step === 4) {
          this.verifymobileNumber()
        }
        else if (this.state.step === 5) {
          this.verifymobileotp(false)
        }
      }
    });
    if (this.props.location != undefined) {
      if (this.props.location.state != undefined) {
        if (this.props.location.state.data != undefined) {
          this.setState({ step: this.props.location.state.data });
        }
        //
        if (this.props.location.state.data != undefined) {
          this.setState({ name: this.props.location.state.name });
        }
      }
    }

    this.myDiv.current.focus();
  }

  componentWillUnmount() {
    this.myDiv.current.removeEventListener("keydown", (e) => {
      //   if (e.key === "Enter" && this.state.page === "login") {
      //     this.submit(e)
      //   }
      if (e.key === "Enter" && this.state.step === 1) {
        this.submitSignup(e);
      }
    });
    clearTimeout(timer)
  }



  namevalidation = (e) => {
    if (NameValidation(e.target.value)) {
      this.setState({ name: e.target.value, nameError: "" });
    }
  };





  submitSignup() {


    this.handlevalidation();
    this.setState({ message: "", errorMessage: "" });
    //
    if (
      this.state.loader === false &&
      this.handlevalidation() === true
    ) {

      console.log('Name', this.state.name, 'Email', this.state.signupemail.trim(), 'reason', this.state.reason, 'feedback', this.state.feedback);

      this.setState({
        loader: false,
        open: true,
        signupmessage: 'Thank you! Your account deletion request will be verified, and we will update you shortly.',
      });

      setTimeout(() => {
        window.location.href = '/';
      }, 3000);

      /*SignUp(
        this.state.name,
        this.state.signupemail.trim(),
        this.state.pannumber,
        Encryption(this.state.confirmpassword)
      )
        .then((response) => {
          //
          this.setState({ loader: true });
          
        })
        .catch((err) => {
          this.setState({ severity: "warning" });
          secureStorage.setItem("UserID", "");
          this.setState({
            loader: false,
            open: true,
            signupmessage: Label.messages.apierror,
          });
        });*/

    }
  }

  handlevalidation = () => {
    var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    var pan = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
    const hasUppercase = /[A-Z]/.test(this.state.newpassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.state.newpassword);
    const hasNumber = /\d/.test(this.state.newpassword);
    let formisvalid = true;
    if (this.state.signupemail.trim() == "") {
      formisvalid = false;
      this.setState({
        signupemailerror: Label.messages.required,
        formisvalid: false,
      });
    } else {
      if (!email.test(this.state.signupemail)) {
        formisvalid = false;
        this.setState({
          signupemailerror: "Invalid Email",
          formisvalid: false,
        });
      }
    }
    if (this.state.name.trim() == "") {
      formisvalid = false;
      this.setState({ nameError: Label.messages.required, formisvalid: false });
    } else {
      if (!NameValidation(this.state.name)) {
        formisvalid = false;
        this.setState({ nameError: "Invalid Name", formisvalid: false });
      }
    }


    return formisvalid;
  };


  emailvalidation = (e) => {
    this.setState({
      signupemail: e.target.value,
      signupmessage: "",
      signupemailerror: "",
    });
  };
  newemailvalidation = (e) => {
    this.setState({
      newEmailAddress: e.target.value,
      newEmailAddressError: "",
    });
  };
  handlesnanckClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  onChangeEmail(e) {
    this.setState({ email: e.target.value, emailError: "" });
    this.props.onChangeEmail(e.target.value);
  }




  terms = () => {
    window.open(window.globalConfig.terms);
  };
  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      intl,
      messagesAuth,
      closeMsg,
      loading,
    } = this.props;
    const { showPassword, showsetPassword, showconfirmPassword } = this.state;
    return (
      <div tabIndex="0" ref={this.myDiv}>
        <Paper className={classes.rootFull}>
          <Grid container xs={12}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              component={Paper}
              elevation={16}
              square
              id="cardImage"
            >
              <CommonLogin />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              component={Paper}
              elevation={1}
              square
              className={[classes.LoginFormCard, "loginFormControl"].join(" ")}
            >
              {/* <div className={classes.paper}> */}
              <div className={classes.pageFormSideWrap}>
                {this.state.step == 1 && (
                  <div
                    className={classes.signupFormLogincard}
                    onSubmit={this.submitSignup}
                  >
                    {/* <h5 className={classes.title}>{labels.Login.Signup}</h5> */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className={classes.cardBox}>

                        <h5 className={classes.titleNew}>

                          Account Deletion Request
                        </h5>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextField"}
                            value={this.state.name}
                            label={labels.Signup.Name}
                            onChange={this.namevalidation}
                            style={{ marginTop: "3%" }}
                            inputProps={{ maxLength: 40 }}
                            errormessage={this.state.nameError}
                          />
                        </FormControl>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextField"}
                            value={this.state.signupemail}
                            label={labels.Signup.Email}
                            onChange={this.emailvalidation}
                            style={{ marginTop: "3%" }}
                            errormessage={this.state.signupemailerror}
                            inputProps={{
                              maxLength: 75,
                              autoComplete: "new-password",
                            }}
                          />
                        </FormControl>

                        {/* Reason Dropdown */}
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )} style={{ marginTop: "5%" }}
                        >
                          <TextField
                            select
                            label="Reason for deleting account?"
                            value={this.state.reason}
                            onChange={(e) => this.setState({ reason: e.target.value })}
                            variant="outlined"
                          >
                            <MenuItem value="Don't use this account">Don't use this account</MenuItem>
                            <MenuItem value="Want to create a new account">Want to create a new account</MenuItem>
                            <MenuItem value="App is too complicated">App is too complicated</MenuItem>
                            <MenuItem value="Too many emails">Too many emails</MenuItem>
                            <MenuItem value="Not relevant to me">Not relevant to me</MenuItem>
                            <MenuItem value="App is slow">App is slow</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </TextField>
                        </FormControl>

                        {/* Feedback Input */}
                        <FormControl fullWidth style={{ marginBottom: "16px", marginTop: "5%" }}>
                          <TextField
                            label="is there more feedback you`d like to share?"
                            value={this.state.feedback}
                            onChange={(e) => this.setState({ feedback: e.target.value })}
                          />
                        </FormControl>



                        <span style={{ paddingTop: "4%", color: window.globalConfig.color.red }}>
                          {this.state.errorMessage != "fail" &&
                            this.state.errorMessage != "Success" &&
                            this.state.errorMessage}
                        </span>
                        {window.globalConfig.terms != undefined && <div
                          style={{
                            fontSize: 12,
                            fontWeight: 800,
                            color: window.globalConfig.color.LABLE_GRAY,
                            marginTop: 10,
                          }}
                          onClick={this.terms}
                        >
                          {labels.Signup.byClickingSubmit}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: window.globalConfig.color.GREEN,
                              cursor: "pointer",
                            }}
                          >
                            {labels.Signup.termsAndCondition}
                          </span>
                        </div>}
                        <div className={classes.btnArea}>
                          {/* <MuiThemeProvider theme={redTheme}> */}

                          <Button
                            variant="contained"
                            onClick={this.submitSignup}
                            disabled={this.state.loader}
                            fullWidth
                            style={{
                              backgroundColor: Color.GREEN,
                              color: Color.WHITE,
                              height: 52,
                            }}
                            size="large"
                            type="submit"
                            className="btnLoginSubmit"
                          >
                            {this.state.loader && (
                              <CircularProgress
                                style={{ color: Color.WHITE }}
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                            {"Send us an EMAIL"}
                            {!this.state.loader && (
                              <ArrowForward
                                className={classNames(
                                  classes.rightIcon,
                                  classes.iconSmall,
                                  classes.signArrow
                                )}
                                disabled={submitting || pristine}
                              />
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}




                <CommonSnackbar
                  message={this.state.signupmessage}
                  handlesnanckClose={this.handlesnanckClose}
                  open={this.state.open}
                  severity={this.state.severity}
                />
              </div>
              {/* </div> */}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}


export default withStyles(styles)(AccountDelete);