import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import SideMenu from '../../components/sidemenu/sidemenu'
import Login from '../login/loginwithotp';
import apiurl from '../../api/url/apiurl'
import { bindActionCreators } from 'redux';
import encryptKYC from '../../api/profile/encryptionkyc';
import Admin from '../../components/admin/admin';
import EKycPage from '../../components/ekyc-redirection/ekyc';
import { Switch, Route, Redirect } from 'react-router-dom';
import Payment from '../payment/index'
import MyProfile from '../../components/sidemenu/profile_sidemenu'
//bonds menu
import TradeSideStocks from '../../containers/bonds/stocks'
import TradeSideTradeBonds from '../../containers/bonds/stocks'
import FdTradeMenu from '../../components/sidemenu/bonds_sidemenu';
import Debentures from '../../containers/fdDebentures/fd';
import Redemption from '../redemption/redemption';
import SWP from '../swp/swp'
import STP from '../stp/stp'
import Switchs from '../switch/switch'
import Label from '../../utils/constants/labels';
import secureStorage from '../../utils/securestorage';
import Risk from '../../components/profile/risk_profile';
import Dashboard from '../dashboard/dashboard';
import Bonds from '../../components/sidemenu/bonds_sidemenu';
//add by yogitha 
import TrackingCard from '../../containers/tracking/Start_tracking_cards ';
import Transact from '../../containers/transact/transact'
import EquityTracking from '../../containers/equity/equity_tracking';
import NotFound from '../../components/Errorhandling/notfound';
import PageNotFound from '../../components/Errorhandling/pagenotfound';
import StepperAdvisory from '../../components/advisoryaccount/stepperadvisory';
import Signup from '../../components/signup/signup';
import AccountDelete from '../../components/accountdelete/AccountDelete';
import Menu from '../../components/menu/menu';
import WelcomePage from '../../components/completedpage/successPage';
import GlobalApiCall from '../../components/globalapicall/globalapicall';
import labels from '../../utils/constants/labels';
import AIF from '../aif/aif';
import AifDataEntry from '../aif/aif_dataentry';
import NpsDataEntry from '../nps/nps_data_entry';
import RealEstate from '../RealEstate/RealEstate';
import RealEstateListing from '../RealEstate/real_estate_listing_page';
import BullionListing from '../RealEstate/real_estate_listing_page';
import Bullion from '../RealEstate/RealEstate';
import Insurance_Data_Entry from '../insurance/insurance_data_entry';
import Insurance_List from '../insurance/insurance_list';
import InsuranceReport from '../insurance/insurancereport';
import Alertnotfication from '../alertnotification/alertnotfication';
import { basicColor } from '../../utils/colors/colors';
import Footer from '../../components/footer/footer';
import EPF from '../epf/epf';
import Uploadcamsandnsdl from '../../components/equity-trading/uploadcamsandnsdl';
import Button from '../../components/custombutton/button';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Nps from '../nps/nps';
import colors from '../../utils/colors/colors';
import { Asset } from '../../utils/mainasset';
import Notificationblock from '../../components/notificationblock/notificationblock';
import moment from 'moment';
import Scrolltotop from '../../components/scrolltotop/scrolltotop';
import Familyreport from '../familyreport/familyreport';
const redirect = [{ pathname: "/trade/Stocks", RedirectUrl: { pathname: labels.Path.EquityTracking, state: { data: "Equity" } } },
{ pathname: "/trade/TradedBonds", RedirectUrl: "/Tracking" },
{ pathname: "/trade/FixedDeposit", RedirectUrl: "/Tracking" },
{ pathname: "/FixedDeposit", RedirectUrl: "/Tracking" },
{ pathname: "/Bonds", RedirectUrl: "/Tracking" },
{ pathname: "/aif", RedirectUrl: "/Tracking" },
{ pathname: "/aifdataentry", RedirectUrl: "/aif" },
{ pathname: "/goldandsilver", RedirectUrl: "/Tracking" },
{ pathname: "/realestate", RedirectUrl: "/Tracking" },
{ pathname: "/goldandsilverdataentry", RedirectUrl: "/goldandsilver" },
{ pathname: "/realestatedataentry", RedirectUrl: "/realestate" },
{ pathname: "/npsdataentry", RedirectUrl: "/nps" },
{ pathname: "/nps", RedirectUrl: "/Tracking" },
{ pathname: "/insurancedataentry", RedirectUrl: "/insurance" },
{ pathname: "/insurance", RedirectUrl: "/Tracking" },
{ pathname: labels.Path.Debentures, RedirectUrl: "/Tracking" },
{ pathname: labels.Path.TradingDetail, RedirectUrl: "/user/schemes" },
{ pathname: labels.Path.EPF, RedirectUrl: "/Tracking" },
{ pathname: labels.Path.UploadProcessing, RedirectUrl: "/Tracking" }]
let familyMembers = ["/Dashboard", "/STP", "/switch", "/redemption", "/SWP"]
class container extends Component {
  constructor(props) {
    super();
    this.state = {
      sessionstore: [],
      ipadd: "",
      versionUpdated: false,
      isReachedBottom: false,
      open: false,
      loader: false,
      message: "",
      openDialogBox: false,
      date: "15 days",
      show: false,
      diffDate: ""
    }
    this.childFunc = React.createRef(null)
    this.logoutFunc = React.createRef(null)
    this.tradeFunc = React.createRef(null)
    this.myRef = React.createRef(null)
    this.memberFunc = React.createRef(null)
    this.l5Func = React.createRef(null)
  }
  getData = async () => {
    // const res = await axios.get('https://geolocation-db.com/json/').then((response) => {
    //   return response
    // }).catch((err) => {
    //   return {data:{
    //     IPv4:""
    //   }}
    // })
    // // console.log(res.data);
    // // setIP(res.data.IPv4)
    // this.setState({ ipadd: res.data.IPv4 })
    // const ip = res?.data?.IPv4
  }
  showDateDiff = () => {
    let diffDate = moment
      .duration(
        moment(window.globalConfig.licenseDate, "DD MMM YYYY").diff(
          moment(moment().format("DD MMM YYYY"), "DD MMM YYYY")
        )
      )
      .asDays()
    // this.setState({ diffDate: diffDate })
    // console.log(this.state.diffDate)
    // console.log(diffDate <= 15 && !diffDate < 1, diffDate > 15, diffDate)
    // if (diffDate <= 15 && Math.sign(diffDate) != -1) {
    //   this.setState({
    //     show: diffDate < 1 ? false : true, date: diffDate < 1 ? "" : `will  be expire soon`

    //     // show: diffDate < 1 ? false : true, date: diffDate < 1 ? "" : `will be within ${diffDate} day${diffDate <= 1 ? "" : "s"}`
    //   })
    // } else if (diffDate > 15) {
    //   this.setState({
    //     show: false,
    //   })

    // } else {
    //   this.setState({ show: true, date: diffDate < 1 ? " has been expired" : ` will be expire soon` })
    // }
    return diffDate
  }
  componentWillUnmount() {
    window.removeEventListener('focus', () => {
      navigator.serviceWorker.getRegistrations()
        .then(registrationsArray => {
          if (registrationsArray.length != 0) {
            registrationsArray[0].update();
          }
        })
    })
    window.removeEventListener('click', () => {
      navigator.serviceWorker.getRegistrations()
        .then(registrationsArray => {
          if (registrationsArray.length != 0) {
            registrationsArray[0].update();
          }
        })
    })
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getData()
    window.addEventListener('focus', () => {
      this.showDateDiff()
      navigator.serviceWorker.getRegistrations()
        .then(registrationsArray => {
          // console.log(registrationsArray)
          if (registrationsArray.length != 0) {
            registrationsArray[0].update();
            //  document.getElementById("new-updates").style.display = "block"
          }
        })
    })

    window.addEventListener("click", () => {
      navigator.serviceWorker.getRegistrations()
        .then(registrationsArray => {
          //  console.log(registrationsArray)
          if (registrationsArray.length != 0) {
            registrationsArray[0].update();
            //document.getElementById("new-updates").style.display = "block"
          }
        })
      this.showDateDiff()
    })

  }
  laterClick = () => {
    document.getElementById("new-updates").style.display = "none"
  }
  refreshPage = () => {
    // clearCacheData = () => {
    // caches.keys().then((names) => {
    //   names.forEach((name) => {
    //     caches.delete(name);
    //   });
    // });
    if (caches) {
      caches.keys().then(async function (names) {
        await Promise.all(names.map(name => caches.delete(name)))
      })
    }
    // alert('Complete Cache Cleared')
    // };
    document.getElementById("new-updates").style.display = "none"
    //  this.setState({ versionUpdated: false })
    //setTimeout(() => {
    window.location.reload(true)
    // }, 1000);
  }
  componentDidUpdate() {
    window.globalConfig.color = secureStorage.getItem("colors") != null ? secureStorage.getItem("colors") : basicColor
  }
  hash = (str) => {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
  between = (x, min, max) => {
    return x >= min && x <= max;
  }
  handleScroll = (e) => {

    const bottom = this.between(Math.round(e.target.scrollHeight - e.target.scrollTop), e.target.clientHeight, 1000)
    // console.log(Math.round(e.target.scrollHeight - e.target.scrollTop), e.target.clientHeight)
    // if (bottom) {
    this.setState({ isReachedBottom: bottom })
    //   console.log("bottom")
    // }else{

    // }
  }

  // handleUpdateServiceWorker = async () => {
  //   document.getElementById("new-updates").style.display = 'none'
  //   await navigator.serviceWorker.getRegistrations()
  //     .then((regs) => {
  //       console.log(regs)
  //       if (regs.length != 0) {
  //         regs.forEach((reg) => {
  //           console.log(reg)
  //           reg.waiting.postMessage({ type: "SKIP_WAITING" });
  //           window.location.reload()
  //         })
  //       } else {
  //         console.log("hi")
  //       }
  //     }

  //     );
  // };
  closeDialogBox = () => {
    clearInterval(this.timer)
    this.setState({ openDialogBox: false, count: 10 })
  }
  handlesnanckClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  createnow = () => {
    this.setState({ loader: true, count: 10 })
    try {
      encryptKYC(this.props.logindetails.EmailID, this.props.logindetails.PhoneNumber).then((res) => {
        // console.log(res)
        this.setState({ loader: false, })
        if (res.status == "S") {
          this.setState({ openDialogBox: true })
          this.timer = setInterval(() => this.handleTimer(res), 1000);
        } else {
          this.setState({ open: true, message: labels.messages.apierror })
        }

      })
    } catch (err) {
      // console.log(err)
    }

  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  handleTimer = (res) => {
    if (this.state.count <= 0) {

      this.setState({ count: 10 })
      this.setState({ openDialogBox: false })
      clearInterval(this.timer)
      window.open(`${apiurl.KYCDomain}${"/MFSelfKYC/ClientSignUp?x1="}${res.encryptedEmail}&x2=${res.encryptedMobile}`)

    } else {
      this.setState({ count: this.state.count - 1 })
    }

  }
  render() {
    let session = secureStorage.getItem('user');
    let session_store = session;
    let currentURL = window.location.pathname
    // console.log(window.location.pathname.includes("/Dashboard") || window.location.pathname.includes("/profile"))
    let url = redirect.filter((item) => item.pathname == currentURL)[0]?.RedirectUrl
    return (
      <React.Suspense fallback='loading...'>
        {/* {console.log(this.showDateDiff() < 1)} */}
        {/* {this.state.versionUpdated && <div style={{ border: `1px solid ${colors.PRIMARY}`, display: "grid", gridTemplateColumns: "88% 13%", backgroundColor: colors.WHITE, zIndex: 3, alignItems: "center", width: "max-content", padding: 10, position: "absolute", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", borderRadius: 5 }}>A New and Improved version of the application is now Available <Button name="Refresh" page="primary" onClick={this.refreshPage}></Button></div>} */}
        <div id='new-updates'>
          <div style={{ display: "grid", gridTemplateColumns: "7% 93%", alignItems: "center", alignContent: "center", justifyContent: "space-between" }}>
            <CardGiftcardIcon></CardGiftcardIcon>
            <div style={{ textAlign: "left", fontWeight: "bold", color: colors.BLACK_PRIMARY, fontSize: 15 }}>A new version of {Asset.source} is available</div>
          </div>

          <div style={{ fontSize: 13, color: colors.LABLE_GRAY, marginLeft: 30 }}>  Click on 'Update' or open in a new tab to get the Latest version</div>
          <div style={{ display: "grid", gridTemplateColumns: "25% 25% 25% 25%", alignItems: "center", justifyContent: "space-between" }}>

            <Button style={{ color: "red", fontSize: 14 }} onClick={this.laterClick} name={"Later"}>Later</Button>
            <div></div>
            <div></div>
            <Button name="Update" page="primary" onClick={this.refreshPage}> </Button>

          </div>
        </div>
        {session_store != null && !window.location.pathname.includes("/admin") && !window.location.pathname.includes("/ekyc") && window.location.pathname != "/notfound" && window.location.pathname != "/404" &&
          <Menu RedirectUrl={url}
            showFamilyMember={!familyMembers?.includes(window.location.pathname)}
            tradeFunc={this.tradeFunc} childFunc={this.childFunc}
            memberFunc={this.memberFunc}
            page={window.location.pathname == "/payment/:buy" || window.location.pathname == "/epf" || window.location.pathname == "/insurancedataentry" || window.location.pathname == "/goldandsilver" || window.location.pathname == "/goldandsilverdataentry" || window.location.pathname == "/insurance" || window.location.pathname == "/nps" || window.location.pathname == "/npsdataentry" || window.location.pathname == "/aifdataentry" || window.location.pathname == "/user/tradingdetails" || window.location.pathname == "/Stocks" || window.location.pathname == "/user/basketdetails" || window.location.pathname == "/aif" || window.location.pathname == "/realestate" || window.location.pathname == "/realestatedataentry" || window.location.pathname == "/bullion" || window.location.pathname == "/bulliondataentry" || window.location.pathname == "/tradableBonds" || window.location.pathname == "/TradedBonds" || window.location.pathname == "/Bonds" || window.location.pathname == "/trade/FixedDeposit" || window.location.pathname == "/Debentures" || window.location.pathname.includes("/UploadProcessing") || window.location.pathname == "/Mftracking" ? "payment" : ""} />}
        {session_store != null && window.globalConfig.isLicenseEnabled &&
          <Notificationblock
            text={`${labels.Dashboard.licenceExpire} ${this.showDateDiff() < 1 ? "has Expired" : "will expire soon"}`}
            show={this.showDateDiff() < 1}
          ></Notificationblock>}
        {/* {console.log(this.props.formNo)} */}
        {session_store != null && (window.location.pathname.includes("/Dashboard") || window.location.pathname.includes("/profile")) &&
          <Notificationblock
            open={this.state.open} message={this.state.message} severity={"warning"} dialogBoxOpen={this.state.openDialogBox} handleClose={this.closeDialogBox} count={this.state.count}
            handlesnanckClose={this.handlesnanckClose} buttonName={labels.Dashboard.createnow} text={labels.Dashboard.alertmessage} onClick={this.createnow} loader={this.state.loader}
            show={window.globalConfig.isKycAllowed && (this.props.formNo == "" || this.props.formNo == 0)} ></Notificationblock>}

        {<GlobalApiCall
          showDateDiff={this.showDateDiff}
          licenceExpire={this.state.diffDate}
          isReachedBottom={this.state.isReachedBottom}
          childFunc={this.childFunc}
          logoutFunc={this.logoutFunc}
          tradeFunc={this.tradeFunc}
          path={window.location.pathname}
          location={this.props.location}
          companyDetails={secureStorage.getItem("company") == null ? {} : secureStorage.getItem("company")}
          logindetails={secureStorage.getItem("loginDetails") == null ? {} : secureStorage.getItem("loginDetails")} />}
        {session_store != null && !window.location.pathname.includes("/admin") && !window.location.pathname.includes("/ekyc") && window.location.pathname != "/notfound" && window.location.pathname != "/404" &&
          <Scrolltotop></Scrolltotop>}
        <div ref={this.myRef} onScroll={this.handleScroll} style={{ height: 'calc(100% - 60px)', overflowY: "auto", overflowX: "hidden" }} id="scroller">
          {/* <div style={{width:"11%"}}></div>
            <div style={{width:"79%"}}> */}
          <Switch>
            <Route path={Label.Path.Login} exact render={(props) => (
              <Login childFunc={this.childFunc} {...props} />
            )} />
            <Route path={Label.Path.AifDataEntry} exact render={(props) => (
              <AifDataEntry childFunc={this.childFunc} {...props} />
            )} />
            <Route path={Label.Path.WelcomePage} component={WelcomePage} />
            <Route path={Label.Path.admin} render={(props) => (
              <Admin childFunc={this.childFunc} {...props} />
            )} />
            {window.globalConfig.isKycAllowed && <Route path={Label.Path.Ekyc} render={(props) => (
              <EKycPage childFunc={this.childFunc} {...props} />
            )} />}
            {window.globalConfig.isUserCanAdd && <Route path={Label.Path.Signup} component={Signup} />}

           <Route path={Label.Path.DeleteAccount} component={AccountDelete} />
            
            {session_store != null ? <Route path={Label.Path.Payment} render={(props) => (
              <Payment childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Alert} render={(props) => (
              <Alertnotfication />)} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.FamilyReport} render={(props) => (
              <Familyreport childFunc={this.childFunc} {...props}></Familyreport>)} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Aif} render={(props) => (
              <AIF childFunc={this.childFunc} flag={'AIF'} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.RealEstateListing} render={(props) => (
              <RealEstateListing childFunc={this.childFunc} isListing="realestate" {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.RealEstate} render={(props) => (
              <RealEstate childFunc={this.childFunc} isListing="realestate" {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Bullion} render={(props) => (
              <Bullion childFunc={this.childFunc} isListing="Bullion"  {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Bullionlisting} render={(props) => (
              <BullionListing childFunc={this.childFunc} isListing="Bullion" {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Profile} component={MyProfile} /> : <Redirect to={"/"}
            />}
            {session_store != null ? window.globalConfig.isTradeAllowed && <Route path={Label.Path.Redemption} render={(props) => (
              <Redemption childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? window.globalConfig.isTradeAllowed && <Route path={Label.Path.Switch} render={(props) => (
              <Switchs childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}

            {session_store != null ? window.globalConfig.isTradeAllowed && <Route path={Label.Path.SWP} render={(props) => (
              <SWP childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}

            {session_store != null ? window.globalConfig.isTradeAllowed && <Route path={Label.Path.STP} render={(props) => (
              <STP childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}

            {session_store != null ? window.globalConfig.isRiskProfileAllowed && <Route path={Label.Path.Risk} component={Risk} /> : <Redirect to={"/"}
            />}
            {session_store != null ?
              <Route path={Label.Path.Dashboard} render={(props) => (
                <Dashboard childFunc={this.childFunc} {...props} memberFunc={this.memberFunc} l5Func={this.l5Func} />
              )} /> : <Redirect to={"/"}
              />}


            {session_store != null ? <Route path={Label.Path.Tracking} render={(props) => (
              <TrackingCard childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}

            {/* {session_store != null ? <Route path={Label.Path.Transact} render={(props) => (
              <Transact childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />} */}


            {session_store != null ? <Route path={Label.Path.UploadProcessing} render={(props) => (
              <Uploadcamsandnsdl childFunc={this.childFunc} {...props}></Uploadcamsandnsdl>
            )} /> : <Redirect to={"/"}
            />}

            {session_store != null ? <Route path={Label.Path.EPF} component={EPF} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.StepperAdvisory} component={StepperAdvisory} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.EquityTracking} render={(props) => (
              <EquityTracking childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ?
              <Route path={Label.Path.SidemenuUser} render={(props) => (
                <SideMenu isReachedBottom={this.state.isReachedBottom} tradeFunc={this.tradeFunc} myRef={this.myRef} childFunc={this.childFunc} {...props} />
              )} /> : <Redirect to={"/"} />}
            {session_store != null ?
              <Route path={Label.Path.Trade} render={(props) => (
                <FdTradeMenu childFunc={this.childFunc} {...props} />
              )} /> : <Redirect to={"/"}
              />}
            {session_store != null ?
              <Route path={Label.Path.Debentures} render={(props) => (
                <FdDebentures childFunc={this.childFunc} {...props} />
              )} /> : <Redirect to={"/"}
              />}

            {session_store != null ?
              <Route path={Label.Path.Payment} component={Payment} /> : <Redirect to={"/"}
              />}
            {session_store != null ? <Route path={Label.Path.Profile} render={(props) => (
              <MyProfile childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}

            {session_store != null ? <Route path={Label.Path.Risk} render={(props) => (
              <Risk childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}

            {session_store != null ? <Route path={Label.Path.NpsDataEntry} render={(props) => (
              <NpsDataEntry childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Nps} render={(props) => (
              <Nps childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Stocks} render={(props) => (
              <TradeSideStocks childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.TradedBonds} render={(props) => (
              <TradeSideTradeBonds childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Bonds} render={(props) => (
              <Bonds childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Debentures} render={(props) => (
              <Debentures childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.InsuranceDataEntry} render={(props) => (
              <Insurance_Data_Entry childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.Insurance} render={(props) => (
              <Insurance_List childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            {session_store != null ? <Route path={Label.Path.InsuranceTab} render={(props) => (
              <InsuranceReport childFunc={this.childFunc} {...props} />
            )} /> : <Redirect to={"/"}
            />}
            <Route path={Label.Path.NotFound} exact component={NotFound} />
            <Route path={Label.Path.PageNotFound} exact component={PageNotFound} />
            <Redirect from='*' to={Label.Path.PageNotFound} />
          </Switch>

        </div>
        {/* </Provider> */}

        {/* <AutoReload url="/version.js" tryDelay={1 * 60 * 1000} childFunc={this.childFunc} loginDetails={secureStorage.getItem("loginDetails")}></AutoReload> */}
        {session_store != null && !window.location.pathname.includes("/admin") && !window.location.pathname.includes("/ekyc") && window.location.pathname != "/notfound" && window.location.pathname != "/404" && <Footer />}
      </React.Suspense>
    )
  }
}
const mapStateToProps = state => ({ 
  userDetails: state.Login?.logindetails != null ? state.Login.logindetails : [],
  formNo: state.Login?.formNo,
  permissionList: state.Login?.companyDetails?.length != 0 ? state.Login.companyDetails[0] : [],
  logindetails: state.Login.logindetails
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default withRouter(

  connect(mapStateToProps, mapDispatchToProps)(container)

);
