import React, { useEffect } from 'react'
import BigText from './bigtext';
import SmallText from './smalltext';
import Rupee from './rupee'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import HeaderBackground from "./headerbackground";

// import MultipleSchemeTabs from '../tab/multiple_schemetabs';
import Label from '../../utils/constants/paymentlabels'
import colors from '../../utils/colors/colors';
import { Divider, List } from '@material-ui/core';
import secureStorage from '../../utils/securestorage'
import '../../../src/utils/stylesheets/payment/stepper.css'
import moment from 'moment';
import labels from '../../utils/constants/labels';
import color from "../../utils/colors/colors";
import { NegativeFinding, formatDate, sortArray } from '../../utils/commonfunction';
import Dropdown from '../dropdown/dropdownschemes'
import { makeStyles, withStyles } from "@material-ui/core/styles";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const style = theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
        borderBottom: `1px solid ${window.globalConfig.color.BORDER}`
    },
    indicator: {
        background: window.globalConfig.color.BROWN
    },
    HeaderDesign: {
        // [theme.breakpoints.between("300", "350")]: {
        //     width: 305,
        //     marginLeft: -30,
        //     marginTop: -10,
        // },
    },
    tabs: {
        borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
        columnCount: '2',
        // columnRuleWidth:'70% 30%',
        // display: "", flexDirection: "row", 
        display: 'grid',
        gridTemplateColumns: "auto",
        // overflow: "overlay",
        // gap: '10px',

        background: window.globalConfig.color.WHITE,
        [theme.breakpoints.down(600)]: {
            // width: "100%",
            overflow: "scroll",
            display: 'grid',
            gridTemplateColumns: "auto",
            gap: '10px',
            // columnCount:'2',

        },
        '& .heighttab .MuiAppBar-colorDefault': {
            backgroundColor: 'revert',
        },

    },
    tabRoot: {
        "& .MuiTab-root:focus": {
            color: window.globalConfig.color.BROWN
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: window.globalConfig.color.BROWN
        }
    },
    flexContainer: {
        [theme.breakpoints.down(1400)]: {
            minHeight: "67px"

        },
    },
    widthtab: {
        // width: "68%",
        zIndex: 0,
        // [theme.breakpoints.down(1400)]: {
        //     minHeight: "67px",
        // },
        // [theme.breakpoints.down('xs')]: {
        //     minHeight: "67px",
        // },
        // [theme.breakpoints.between(1000, 1050)]: {
        //     // width: "68%",
        //     height: "40px",
        //     marginTop: "15px"
        // }
    },
    dropDownPopUp: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '-2%', marginLeft: '2%'
        },
    },
    popupdesign: {
        // whiteSpace: 'nowrap',
        display: 'flex',
        // marginTop: "1%",
        [theme.breakpoints.down('1300')]: {
            minHeight: "67px",
            // whiteSpace: 'nowrap',
            display: 'flex',
        }
        // marginLeft: "15%"
    },

    accountMarginTop: {
        // marginTop:'-2%',
        [theme.breakpoints.down('601')]: {
            marginTop: '-3.5%',
        },
        [theme.breakpoints.between('400', '600')]: {
            marginLeft: '40%',
            width: "100%"
        },
        [theme.breakpoints.down('350')]: {
            marginLeft: "-12%",

        },
    },
    badge: {
        display: "flex",
        border: `1px solid ${window.globalConfig.color.BROWN}`,
        borderRadius: "50%",
        padding: 2,
        minWidth: 16,
        minHeight: 16,
        alignItems: "center",
        justifyContent: "center",
        fontSize: "x-small"
    },
    tabAlignment: {
        display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10
    },
    container: {
        // padding: "20px 20px 0px 30px",
        margin: '5%',
    },
    bottomPadding: {
        // padding: "20px 20px 25px 30px", 
    },
    flex: {
        display: 'flex'
    },
    flexSpace: {
        padding: '0px 70px 0px 0px'
    },
    flexSpaceSIP: {
        padding: '0px 10px 0px 0px'
    },
    bigSpacing: {
        padding: 14
    },
    smallSpacing: {
        padding: 3
    },
    bottomBackground: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
        width: "130%", //Modified 100% to 131%
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    bottomBackgroundonetime: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
        width: "100%",
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginTop: 100
        // marginTop: "10%"
    },
    footerBackground: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginLeft: -20,
    },
    footerBackgroundonetime: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginTop: "10%",
        marginLeft: -20,
    },
    bottomRowOne: {
        padding: "5px 5px 5px 5px"
    },
    bottomRowTwo: {
        // padding: "5px 5px 5px 20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        padding: "5px 5px 5px 10px"
    },
    divider: {
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginLeft: 20,
        marginRight: 20
    },
    totalSIPAmount: {
        color: window.globalConfig.color.WHITE,
        fontSize: 14
    },
    firstRowRupee: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: 15
    },
    secondRowRupee: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: 13,
        paddingLeft: 10
    },
    firstRowAmount: {
        color: window.globalConfig.color.WHITE,
        fontSize: 16,
        textAlign: "left",
        fontWeight: "500"
    },
    secondRowAmount: {
        color: window.globalConfig.color.WHITE,
        fontSize: 13
    },
    firstPayment: {
        color: window.globalConfig.color.WHITE,
        fontSize: 13
    },
    mulipleTab: {
        width: 200,
    },
    summaryListContainer: {
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 10
        // paddingTop: 10,
        // paddingLeft: 10
    },
    tabContent: {
        width: "100%",
        overflowY: "hidden",
        paddingRight: 17,
        boxSizing: "content-box"
    },
    schemeSelected: {
        margin: "10px 20px 30px 0px",
        cursor: "pointer",
        fontSize: 14,
        color: window.globalConfig.color.BROWN,
        borderBottomWidth: 1,
        borderBottomColor: window.globalConfig.color.BROWN
    },
    labelColumnStyle: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: 10,
        // paddingLeft: 10
    },

    tableContainer: {
        margin: '5%',
    },
})
class Summary extends React.Component {
    constructor(props) {
        super(props);
        const User = secureStorage.getItem("user");
        this.state = {
            value: 0,
            summaryDetails: [],
            totalAmount: "",
            todayPayment: "",
            noofSchemes: ""
        }
    }
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    }
    componentDidMount() {

    }

    render() {
        const { classes } = this.props;
        let purchaseData = this.props.summaryDetails
        console.log('this.props.summaryDetails.multipleSelect', this.props.summaryDetails.multipleScheme);
        let schemeSum = purchaseData?.multipleSelect ? purchaseData?.multipleScheme.reduce(function (accumulator, curValue) {
            return accumulator + curValue.Amount
        }, 0) : 0
        let todayPayment = purchaseData?.multipleSelect ? purchaseData?.multipleScheme.filter((item) => item.ISFirstPayment).reduce(function (accumulator, curValue) {
            return accumulator + curValue.Amount
        }, 0) : 0
        return (<div className={classes.summaryListContainer}>
            <div className={classes.HeaderDesign}>
                <HeaderBackground
                    text={"Summary Details"}
                />
            </div>
            {this.props.summaryDetails.multipleSelect ? <>
                <div className={classes.root} >
                    <AppBar position="static" color="default"
                        classes={{ root: classes.widthtab }}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"

                            classes={{ root: classes.tabRoot }}

                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}
                        >
                            {this.props.summaryDetails.multipleScheme.map((item, index) => {
                                return <Tab label={"Scheme " + (index + 1)}  {...a11yProps(index)} className={classes.tab} />
                            })}

                        </Tabs>

                    </AppBar>
                </div>


                {this.props.summaryDetails?.multipleScheme.map((item, index) => {
                    return <TabPanel value={this.state.value} index={index}>
                        <SummaryList List={this.props.summaryDetails} multipleSelect={true} type={this.props.summaryDetails.InvesmentType} total={this.props.summaryDetails.multipleScheme.filter((val) => val.ISFirstPayment).length} todayPayment={todayPayment} schemeSum={schemeSum} noofSchemes={this.props.noofSchemes} folioChange={this.props.folioChange} classes={classes} {...this.props} Details={item}></SummaryList>
                    </TabPanel>
                })}</>
                : this.props.summaryDetails?.multipleScheme.map((item, index) => {
                    return <SummaryList List={this.props.summaryDetails} total={this.props.summaryDetails.length} todayPayment={this.state.todayPayment} folioChange={this.props.folioChange} classes={classes} Details={item} {...this.props}></SummaryList>
                })}
        </div>

        )
    }
}
class SummaryList extends React.Component {

    render() {
        const { classes, Details } = this.props;

        console.log('Details', Details);
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: 73 }}>
                <div style={{ gap: 10, paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}><BigText text={Label.Summary.Header2} size="16px" />
                    <SmallText text={Details.SchemeName} weight="500" size="17px" />
                    <div className={classes.labelColumnStyle}>

                        <BigText text={Label.Summary.OrderType} size="14px" />

                        <SmallText text={this.props.type?.toUpperCase()} weight="normal" size="14px" />

                    </div>
                    {this.props.type === "Sip" ? <>
                        <div className={classes.labelColumnStyle}>
                            <BigText text={Label.Summary.SIPAmount} size="14px" />
                            <SmallText text={NegativeFinding(Details.Amount, "", "", "", "rupee")} weight="normal" size="14px" />
                        </div>

                        <div className={classes.smallSpacing}></div>
                        <div className={classes.labelColumnStyle}>
                            <BigText text={Label.Summary.SIPFrequency} size="14px" />

                            <SmallText text={Details.SipFrequency == undefined ? Details.Frequency : Details.SipFrequency} weight="normal" size="14px" />
                        </div>

                        <div className={classes.smallSpacing}></div>
                        <div className={classes.labelColumnStyle}>
                            <BigText text={labels.cart.SIPStartDate} size="14px" />
                            {/* {console.log(Details)} */}
                            <SmallText text={formatDate(Details.Sipdate)} weight="normal" size="14px" />
                        </div>
                        <div className={classes.smallSpacing}></div>
                        <div className={classes.labelColumnStyle}>
                            <BigText text={Label.Summary.NOOfInstalments} size="14px" />

                            <SmallText text={Details.installments || Details.Instalments || 180} weight="normal" size="14px" />
                        </div>
                        <div className={classes.smallSpacing}></div>

                        <div className={classes.smallSpacing}></div>

                    </> :

                        <div className={classes.labelColumnStyle}>
                            <BigText text={Label.Summary.OneTimeAmount} size="14px" />

                            <SmallText text={NegativeFinding(Details.Amount, "", "", "", "rupee")} weight="normal" size="14px" />
                        </div>

                    }
                    {/* {console.log(Details, Details.FolioList, JSON.parse(Details?.FolioList).filter((val) => val.MutualFundUCC != "").length,
                        sortArray([...JSON.parse(Details?.FolioList).filter(
                            (ele, ind) =>
                                ind ===
                                JSON.parse(Details?.FolioList).findIndex((elem) => elem.FolioNo === ele.FolioNo && ele.MutualFundUCC == Details.MutualFundUCC)
                        )]))} */}
                    {this.props.page != "basket" && <Dropdown type={"normalwithsearch"}
                        autoselect={Details.folioNumber == undefined || Details.folioNumber == "" ? true : false}
                        handleOnChange={(val) => this.props.folioChange(val, Details)}
                        id="payementdetails2"
                        label={labels.payment.folioNumber}
                        value={Details.folioNumber}
                        // error={props.errorFolioNumber}
                        placeholder={labels.payment.selectFolio}
                        List={Details?.FolioList == "NA" ? [{ "label": "Create New", "subLabel": "New", "value": "New" }] : JSON.parse(Details?.FolioList).filter((val) => val.MutualFundUCC != "").length != 0 ? sortArray([...JSON.parse(Details?.FolioList).filter(
                            (ele, ind) =>
                                ind ===
                                JSON.parse(Details?.FolioList).findIndex((elem) => elem.FolioNo === ele.FolioNo && ele.MutualFundUCC == Details.MutualFundUCC)
                        ).map((item) => {

                            return { label: item.FolioNo, subLabel: item.SchemeID == Details.SchemeID ? "This Scheme"+" | Current Value: "+ NegativeFinding(Details.CurrentMarketValuation, "", "", "", "rupee")+" | SIP: "+ NegativeFinding(Details.Amount, "", "", "", "rupee") : "Other Scheme" +" | Current Value: "+ NegativeFinding(Details.CurrentMarketValuation, "", "", "", "rupee")+" | SIP: "+ NegativeFinding(Details.Amount, "", "", "", "rupee"), id: Details?.folioNumber == item.FolioNo ? 1 : 2, value: item.FolioNo }
                        }), { "label": "Create New", "subLabel": "New", "value": "New", id: Details?.folioNumber == "New" ? 1 : 3 }], "id", "ASC") : [{ "label": "Create New", "subLabel": "New", "value": "New" }]} />
                    }
                    {
                        this.props.type == "Sip" &&

                        <SmallText text={this.props.sevenDaysLogic(Details.ISFirstPayment, Details.Sipdate)} color={window.globalConfig.color.red} weight="normal" size="14px" />
                    }
                </div>

                {
                    this.props.type === "Sip" && this.props.multipleSelect ? <div style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10, backgroundColor: colors.BLACK_PRIMARY }}>
                        <div style={{ display: "flex", flexDirection: "row", textAlign: "left", color: colors.WHITE, padding: 10, gap: 10 }}>
                            <div>{"Total "}{this.props.type == "Sip" ? "SIP" : "One Time"}{" Amount for Month "}</div>
                            <div >{NegativeFinding(this.props.schemeSum, "", "", "", "rupee")}</div>
                        </div>
                        {this.props.List.multipleSelect && <> <Divider className={classes.divider} />
                            <div className={classes.bottomRowTwo}>

                                <div className={classes.firstPayment}>{Label.Summary.FirstPayment} ({this.props.List.multipleScheme.filter((val) => val.ISFirstPayment).length} {this.props.List.multipleScheme.filter((val) => val.ISFirstPayment).length == 1 ? Label.Summary.Scheme : Label.Summary.Schemes}) :</div>
                                <span className={classes.secondRowRupee}>{Label.Summary.rupeeSymbol} </span>
                                <span className={classes.secondRowAmount}>{NegativeFinding(this.props.todayPayment, "", "", "", "number")}</span>

                            </div></>}
                    </div> : <div></div>}

            </div >
        )
    }
}
export default withStyles(style)(Summary);
