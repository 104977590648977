import React from "react";
import getBenchMarkData from "../../api/basket/benchmarkdata";
import { NegativeFinding, replaceNull } from "../../utils/commonfunction";
import getBenchMarkDetails from "../../api/portfolio/benchmarkapi";
import Deletepopup from "../../components/popup/deletepopup"
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Dropdownschemes from "../dropdown/dropdownschemes";
import { BiChevronDown } from "react-icons/bi";
import colors from "../../utils/colors/colors";
import { Skeleton } from "@material-ui/lab";
const styles = (theme) => ({

})
class BenchMarkCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            benchMarkData: [],
            benchMarkID: "", benchMarkName: "", benchMarkXirr: 0
        }
    }
    async componentDidMount() {
        if (this.props.myRef != undefined) {
            this.props.myRef.current = this.benchAPI
        }
        if (this.props.productID != 15) {
            await this.benchMarkDataList()
        }
        // await this.benchAPI(this.state.benchMarkID)
    }
    componentDidUpdate(prevProps) {
        const { date, L5ID, clientID } = this.props
        if (prevProps.date != date || prevProps.L5ID != L5ID || prevProps.clientID != clientID) {
            if (this.props.productID != 15) {
                this.onChangeBenchMark(this.state.benchMarkData?.filter((item) => item.label == "NIFTY 500"))

                // this.benchAPI(this.state.benchMarkData?.filter((item) => item.label == "NIFTY 500")[0].value)
            }
        }
    }
    benchMarkDataList = () => {
        getBenchMarkData().then(async (response) => {
            let res = replaceNull(response, "NA")
            if (res.status == "S" && res.data != "NA") {
                await this.setState({
                    benchMarkData: res.data.map((item) => {
                        return {
                            ...item,
                            label: item.BenchMarkName,
                            value: item.ID,
                            isChecked: false
                        }
                    }),
                    // benchMarkID: res.data[0].ID, benchMarkName: res.data[0].BenchMarkName
                })
                this.onChangeBenchMark(res.data.map((item) => {
                    return {
                        ...item,
                        label: item.BenchMarkName,
                        value: item.ID,
                        isChecked: false
                    }
                }).filter((item) => item.label == "NIFTY 500"))
            } else {
                this.setState({ benchMarkData: [] })
            }
        })
    }

    onChangeBenchMark = async (data) => {
        // this.setState({ benchMarkID: data.label })
        await this.setState({
            benchMarkData: this.state.benchMarkData.map((item) => {
                return { ...item, isChecked: item.label == data[0].label }
            })
        })
        this.benchAPI(data[0]?.value)
    }
    benchAPI = (BenchMarkID) => {
        this.setState({ loadBenchMark: true })
        getBenchMarkDetails(this.props.logindetails.SessionID, this.props.logindetails.ClientID, this.props.date, this.props.productID, 0, this.props.L5ID, 0, 0, BenchMarkID).then((res) => {
            if (res.status == "S" && replaceNull(res.data, "NA") != "NA") {
                this.setState({ loadBenchMark: false, benchMarkXirr: res.data[0]?.XIRR || 0 })

            } else {
                this.setState({ loadBenchMark: false, benchMarkXirr: 0 })

            }
            // if (res.status == "S") {
            //     this.props.bindBenchMark(res.data[0].XIRR)
            // } else {
            //     this.props.bindBenchMark(0)
            // }
        })
        this.props.onClose()
        // this.openBenchMarkPopup()
    }
    render() {
        return (
            <Dropdownschemes
                type={"normalList"}
                List={this.state.benchMarkData}
                // value={this.state.benchMarkID}
                handleOnChange={this.onChangeBenchMark}
                style={{ height: 0, padding: 0 }}
            >

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <span style={{
                        color: colors.LABLE_GRAY,
                        fontSize: 14
                    }} >
                        Benchmark Xirr
                    </span>
                    <BiChevronDown
                        style={{ width: 25, height: 25, minWidth: 25, minHeight: 25, color: "grey" }}
                    />
                </div>
                {this.state.loadBenchMark ? <Skeleton style={{ height: "4vh", margin: 10 }}></Skeleton> : <div style={{ fontSize: 18, fontWeight: 600 }}><span style={{ color: NegativeFinding(this.state.benchMarkXirr) }}>{NegativeFinding(this.state.benchMarkXirr, "", "", "", "percentage")}</span></div>}
                    

            </Dropdownschemes>
        )
    }
}
const mapStateToProps = (state) => ({
    // sessionId: state.Login.sessionID,
    formNo: state.Login?.formNo == undefined || state.Login.formNo == null ? 0 : state.Login.formNo,

    date: state.Login.date,
    L5ID: state.Login.L5ID,
    clientID: state.Login.clientID,
    logindetails: state.Login.logindetails,
    UserName: state.Login.changedUserName,
    permissionList: state.Login.companyDetails.length != 0 ? state.Login.companyDetails : [],
    // loginTime: state.Login.loginTime,
    L5report:
        state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
            ? state.L5report.L5report.length == 1 ? [...state.L5report.L5report.map((item) => {
                return { ...item, Name: item.PortfolioName };
            })] : [

                ...state.L5report.L5report.map((item) => {
                    return { ...item, Name: item.PortfolioName };
                }),
            ]
            : [],
    // loader: state.Login.loadercontinue,
    // getAllSipCart: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart,
    // getAllOnetimeCart: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart,


});
export default withStyles(styles)(
    withRouter(connect(mapStateToProps, null)(BenchMarkCard))
);

// export default BenchMarkCard